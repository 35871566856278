/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { SearchOutlined } from '@ant-design/icons'
import { Avatar, Button, Drawer, Dropdown, Input, Menu, MenuProps, Modal } from 'antd'
import { useEffect, useState } from 'react'
import "./styles.header.scss"
// import "./styles.css";
import { Link, useNavigate } from "react-router-dom"
// import CRegisterModal from './CRegisterModal';
import { MenuOutlined, UserOutlined, DownOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion'
import Utils from '../../common/utils'
import UserIcon1 from '../../images/user-1.png';
import UserIcon2 from '../../images/user-2.png';
import UserIcon3 from '../../images/user-3.png';

import SearchIcon from '../../images/Search_Icon.png'
import { useDispatchRoot, useSelectorRoot } from '../../redux/store'
import { ChangeTypeRequest } from '../../common/define-identity'
import { changeTypeRequest } from '../../redux/controller'
import SubMenu from 'antd/lib/menu/SubMenu'
import axios from 'axios'
import ResultAPI from '../../api/results/results.api'
import SYSTEM_CONSTANTS from '../../common/constants'
import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
    // scales,
} from 'chart.js';
const lstColor = ['rgba(239, 83, 80, 0.7)', 'rgb(255, 167, 38, 0.7)', 'rgb(255, 213, 79, 0.7)', 'rgb(102, 187, 106, 0.7)', 'rgb(66, 165, 245, 0.7)', 'rgb(92, 107, 192, 0.7)', 'rgb(171, 71, 188, 0.7)', 'rgb(236, 64, 122, 0.7)']

interface MyProps {
    // setIsLogout: React.Dispatch<React.SetStateAction<boolean>>
}

// Phần header của trang web
export const CHeader = (props: MyProps) => {
    const [visible, setVisible] = useState(false); // Biến thể hiện nút thu gọn menu có đang mở hay không
    const [current, setCurrent] = useState<string>('1') // Biến thể hiện giá trị cho nút hiện tại
    const { user, tmpUser } = useSelectorRoot((state) => state.login);
    const [userIcon, setUserIcon] = useState<string>(UserIcon1);
    const [isLogin, setIsLogin] = useState<boolean>(false)
    const [textLogo, setTextLogo] = useState<string>("V.INNOVATE");
    const [items, setItems] = useState<MenuProps['items']>([])
    const navigate = useNavigate();
    const dispatch = useDispatchRoot();
    const [positionIdStaffInChangeStartUpInnovate, setPositionIdStaffInChangeStartUpInnovate] = useState<string>('63fed40404d0683c30798350')
    const [userType, setUserType] = useState<string>('')
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [systemStatistics, setSystemStatistics] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [options, setOptions] = useState<any>(null);

    useEffect(() => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(true);
            if (user?.type === 'UINNOVATE' || user?.type === 'UINNOVATE_SHORT') {
                setUserIcon(UserIcon1);
                setTextLogo('U.INNOVATE');
            }
            if (user?.type === 'UIMPACT') {
                setUserIcon(UserIcon2);
                setTextLogo('U.IMPACT');
            }
            if (user?.type === 'PINNOVATE') {
                setUserIcon(UserIcon3);
                setTextLogo('P.INNOVATE');
            }
        }
    });

    useEffect(() => {

        console.log(user);


        let userType = localStorage.getItem('userType') ? localStorage.getItem('userType') : ''
        if (userType) {
            userType = userType.slice(1);
            userType = userType.slice(0, userType.length - 1);
            console.log(userType);
            if (user?.role !== 'admin') {
                setItems(
                    [
                        {
                            key: '2',
                            label: (
                                <Link to='/user-statistics' >
                                    Kết quả đánh giá
                                </Link>
                            ),
                        },
                        {
                            key: '1',
                            label: (
                                <Link to='/home' onClick={onClickLogout}>
                                    Đăng xuất
                                </Link>
                            ),
                        },

                    ],
                )
            }
            else if (user?.role === 'admin') {
                setItems(
                    [
                        {
                            key: '1',
                            label: (
                                <Link to='/home' onClick={onClickLogout}>
                                    Đăng xuất
                                </Link>
                            ),
                        },

                    ],
                )
            }

        }
    }, [user])

    // Kiểm tra xem đường dẫn đang là gì để set thuộc tính đã click cho header
    useEffect(() => {
        if (window.location.pathname === '/test')
            setCurrent('2')
        if (window.location.pathname === '/news')
            setCurrent('3')
        if (window.location.pathname === '/about_us' || window.location.pathname === '/privacy-policy' || window.location.pathname === '/legal-notice')
            setCurrent('4')
        if (window.location.pathname === '/home')
            setCurrent('1')
        if (window.location.pathname === '/user-management')
            setCurrent('5')
        if (window.location.pathname === '/year-management')
            setCurrent('6')
    }, [window.location.pathname])

    useEffect(() => {
        if (tmpUser)
            setTimeout(() => window.location.reload(), 100);

        // window.location.reload();
    }, [tmpUser])

    useEffect(() => {
        fetchDataSystemStatistics();
    }, [])

    useEffect(() => {
        console.log(systemStatistics);
        if (!systemStatistics) return;
        const data = systemStatistics.data.listAverageOfCriterias
        const series = data
        console.log(series);

        // Cho series thành mảng 1 chiều
        let series1D: any[] = []
        series.forEach((item: any) => {
            series1D.push(Math.round(item.averageOfCriteria * 10) / 10)
        })
        console.log(series1D);

        let labels: any[] = []
        series.forEach((item: any) => {
            labels.push(item.criteriaName)
        })
        console.log(labels);


        // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
        let colors: string[] = []
        series.forEach((item: any, index: any) => {
            // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
            // colors.push('#FF0000')
            // colors sẽ lấy ra màu ngẫu nhiên
            colors.push(lstColor[index])
        })
        console.log(colors);

        const options = {
            responsive: true,
            scales: {
                r: {
                    suggestedMin: 0,
                    // suggestedMax: 5,
                    grid: {
                        circular: true,
                        colors: '#000',
                    },
                    angleLines: {
                        display: true,
                        color: '#e6e6e6',
                        lineWidth: 1,
                    },
                    ticks: {
                        stepSize: 1,
                        beginAtZero: true,
                        backdropColor: 'transparent',
                        font: {
                            size: 19,
                        },
                        color: '#000',
                    },
                    lineArc: true,

                },
            },
            plugins: {
                legend: {
                    // display: false

                },
            },
            datalabels: {
                display: true,
                color: 'black'
            },
            cutout: '50%',
            layout: {
                padding: 5
            },
            hover: {
                mode: 'nearest'
            },
            animation: {
                duration: 2000
            },
            tooltips: {
                enabled: true,
            },
        };

        const dataTmp = {
            labels: labels,
            datasets: [
                {
                    label: 'Điểm: ',
                    data: series1D,
                    backgroundColor: colors,
                    borderColor: '#000',
                    borderWidth: 1,
                    innerRadius: 50,
                    indexAxis: 'r',
                },
            ],
        }

        setOptions(options)
        setData(dataTmp)
        // setLstData(dataTmp)
    }, [systemStatistics])

    const fetchDataSystemStatistics = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        await axios.get(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_SYSTEM_STATISTICS}`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then((res) => {
                console.log(res.data.data);
                setSystemStatistics(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    // Hiển thị ra nút thu gọn menu
    const showDrawer = () => {
        setVisible(true);
    }

    // Đóng nút thu gọn menu
    const onClose = () => {
        setVisible(false);
    }

    // Gán giá trị cho biến nút hiện tại
    const handleClick = (e: { key: any }) => {
        setCurrent(e.key);
    };
    const onClickLogout = () => {
        Utils.removeItemLocalStorage('token');
        Utils.removeItemLocalStorage('userPosition');
        Utils.removeItemLocalStorage('userMail');
        Utils.removeItemLocalStorage('theme');
        Utils.removeItemLocalStorage('userName');
        Utils.removeItemLocalStorage('userType');

        setTimeout(() => window.location.reload(), 100);
        // window.location.reload();
    }

    const handleClickChangeTypeUINNOVATE = () => {
        if (user?.type !== 'UINNOVATE' && user?.type !== 'UINNOVATE_SHORT') {

            const type = (user?.position.id !== positionIdStaffInChangeStartUpInnovate) ? 'UINNOVATE_SHORT' : 'UINNOVATE'

            const req: ChangeTypeRequest = {
                type: type,
                additionalProp1: {},
            }
            dispatch(changeTypeRequest(req))
        }
        // setTimeout(() => window.location.reload(), 2000);
        // window.location.reload();

    }
    const handleClickChangeTypeUIMPACT = () => {
        if (user?.type !== 'UIMPACT') {
            const req: ChangeTypeRequest = {
                type: 'UIMPACT',
                additionalProp1: {},
            }
            dispatch(changeTypeRequest(req))
        }
        // setTimeout(() => window.location.reload(), 2000);
        // window.location.reload();

    }
    const handleClickLogin = () => {
        navigate('/login');
    }

    const onClickUserStatistics = () => {
        setVisible(false);
        navigate('/user-management');
    }

    const onClickYearStatistics = () => {
        setVisible(false);
        navigate('/year-management');
        
    }
    return (
        <div className={'main-header'}>
            {(user && user.role === 'admin') &&
                <>
                    <div className='header-logo'>
                        <div id='main-header-logo' className='logo-text' onClick={() => navigate('/user-management')}>VINNOVATE</div>
                    </div>
                    <Menu
                        className={`header-menu ${isLogin ? 'login' : ''} ${user?.role === 'admin' ? 'admin' : ''} `}
                        onClick={handleClick}
                        defaultSelectedKeys={[current]}
                        selectedKeys={[current]}
                        mode="horizontal"
                        overflowedIndicator={<MenuOutlined />}
                    >
                        <Menu.Item key="5" >
                            <Link to={'/user-management'}>Thống kê người dùng</Link>
                        </Menu.Item>
                        <Menu.Item key="6">
                            <Link to={'/year-management'}>Thống kê theo năm</Link>
                        </Menu.Item>

                    </Menu>
                    {!isLogin &&
                        <>
                            <motion.div className='header-button'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}>
                                <Button onClick={handleClickLogin}>Đăng Ký / Đăng Nhập</Button>
                            </motion.div>
                        </>
                    }
                    {isLogin &&

                        <div className='header-avatar-content' style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                                <div className='header-avatar-right'>
                                    <div
                                        className='header-avatar-name'>{user?.name}
                                    </div>
                                    {/* <div
                                        className='header-avatar-position'>{user?.position.description}
                                    </div> */}
                                </div>
                                <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                                    <DownOutlined style={{ cursor: 'pointer' }} />
                                </Dropdown>
                            </div>
                        </div>
                    }
                    <>
                        <Button className={`menubtn ${isLogin ? 'login' : ''}`} type="primary" shape="circle" icon={<MenuOutlined />} onClick={showDrawer} ></Button>
                        <Drawer
                            title={
                                <div className='header-logo'>
                                    <Link to={'/'} className='logo-text'>V.innovate</Link>
                                </div>
                            }
                            placement="right"
                            onClose={onClose}
                            visible={visible}>
                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                <Button type='text'
                                    onClick={onClickUserStatistics}
                                >
                                    Thống kê người dùng
                                </Button>
                                <Button type='text'
                                    onClick={onClickYearStatistics}
                                >
                                    Thống kê theo năm
                                </Button>
                                {!isLogin && <Button type="text" href="/login" >Đăng nhập / Đăng ký</Button>}
                            </div>
                        </Drawer>
                    </>
                </>
            }
            {user?.role !== 'admin' &&
                <>
                    <div className='header-logo'>
                        <div id='main-header-logo' className='logo-text' onClick={() => navigate('/home')}>{textLogo}</div>
                    </div>
                    <Menu
                        className={`header-menu ${isLogin ? 'login' : ''}`}
                        onClick={handleClick}
                        defaultSelectedKeys={[current]}
                        selectedKeys={[current]}
                        mode="horizontal"
                        overflowedIndicator={<MenuOutlined />}
                    >
                        <Menu.Item key="1" >
                            <Link to={'/home'}> Trang chủ </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to={'/test'}>Đánh giá </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="3" >
                    <Link to={'/news'}>Tin tức</Link>
                </Menu.Item> */}
                        <Menu.Item key="4">
                            <Link to={'/about_us'}>Về chúng tôi</Link>
                        </Menu.Item>
                        {(user?.type === 'UINNOVATE' || user?.type === 'UIMPACT' || user?.type === 'UINNOVATE_SHORT') &&
                            <SubMenu
                                key="5"
                                title={
                                    <>
                                        <div>
                                            Chọn công cụ đánh giá
                                        </div>
                                        <DownOutlined />
                                    </>
                                }
                                className='choose-type'
                            >
                                <Menu.Item key='6'>
                                    <Link to={''} onClick={handleClickChangeTypeUINNOVATE}>U.INNOVATE</Link>
                                </Menu.Item>
                                <Menu.Item key={7}>
                                    <Link to={''} onClick={handleClickChangeTypeUIMPACT}>U.IMPACT</Link>
                                </Menu.Item>
                            </SubMenu>
                        }
                    </Menu>
                    {!isLogin &&
                        <>
                            <motion.div className='header-button'
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}>
                                <Button onClick={handleClickLogin}>Đăng Ký / Đăng Nhập</Button>
                            </motion.div>
                        </>
                    }
                    {isLogin &&

                        <div className='header-avatar-content' style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                                <div className='header-avatar-right'>
                                    <div
                                        className='header-avatar-name'>{user?.name}
                                    </div>
                                    <div
                                        className='header-avatar-position'>{user?.position.description}
                                    </div>
                                </div>
                                <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                                    <DownOutlined style={{ cursor: 'pointer' }} />
                                </Dropdown>
                            </div>
                        </div>
                    }
                    <>
                        <Button className={`menubtn ${isLogin ? 'login' : ''}`} type="primary" shape="circle" icon={<MenuOutlined />} onClick={showDrawer} ></Button>
                        <Drawer
                            title={
                                <div className='header-logo'>
                                    <Link to={'/'} className='logo-text'>V.innovate</Link>
                                </div>
                            }
                            placement="right"
                            onClose={onClose}
                            visible={visible}>
                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                <Button type="text" href="/" >Trang chủ</Button>
                                <Button type="text" href="/test" >Đánh giá</Button>
                                <Button type="text" href="/about_us" >Về chúng tôi</Button>
                                <Menu
                                    className='header-menu-drawer'
                                    onClick={handleClick}
                                    defaultSelectedKeys={[current]}
                                    selectedKeys={[current]}
                                    mode="horizontal"
                                    overflowedIndicator={<MenuOutlined />}
                                >
                                    {(user?.type === 'UINNOVATE' || user?.type === 'UIMPACT' || user?.type === 'UINNOVATE_SHORT') &&
                                        <SubMenu
                                            key="5"
                                            title="Chọn công cụ đánh giá"
                                            className='choose-type'
                                        >
                                            <Menu.Item key='6'>
                                                <Link to={''} onClick={handleClickChangeTypeUINNOVATE}>U.INNOVATE</Link>
                                            </Menu.Item>
                                            <Menu.Item key={7}>
                                                <Link to={''} onClick={handleClickChangeTypeUIMPACT}>U.IMPACT</Link>
                                            </Menu.Item>
                                        </SubMenu>
                                    }
                                </Menu>
                                {!isLogin && <Button type="text" href="/login" >Đăng nhập / Đăng ký</Button>}
                            </div>
                        </Drawer>
                    </>
                </>
            }

            <Modal
                title="Thông tin người dùng"
                visible={isShowModal}
                onOk={() => setIsShowModal(false)}
                onCancel={() => setIsShowModal(false)}
                footer={null}
                className='user-statistics-modal'
            >
                <div className='user-statistics'>
                    <div className='user-statistics-info'>
                        <div className='user-statistics-info-name'><b>Username:</b> {user?.name}</div>
                        <div className='user-statistics-info-position'><b>Vai trò:</b> {user?.position.description}</div>
                    </div>

                    <div className='user-statistics-chart'>
                        <div className='title-content-score'>Thống kê kết quả đánh giá</div>
                        {data &&
                            <div className="charts">
                                <div className='chart'>
                                    <PolarArea data={data} options={options} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}
