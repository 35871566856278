/* eslint-disable jsx-a11y/iframe-has-title */
import { Button, Modal, notification } from 'antd';
import { Variants, motion, useTransform, useViewportScroll } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageOfIntro from '../../images/home_image_1.png';
import HowToUse from '../../images/HowToUse.png';
import HowToUse1 from '../../images/how-to-use1.png';
import HowToUse2 from '../../images/how-to-use2.png';
import HowToUse3 from '../../images/how-to-use3.png';
import HowToUse4 from '../../images/how-to-use4.png';
import FirstTutorial from '../../images/fisrt-tutorial.png';
import SecondTutorial from '../../images/second-tutorial.png';
import Tutorial1 from '../../images/tutorial-1.png';
import Tutorial2 from '../../images/tutorial-2.png';
import Tutorial3 from '../../images/tutorial-3.png';
import Tutorial4 from '../../images/tutorial-4.png';
import Tutorial5 from '../../images/tutorial-5.png';
import Tutorial6 from '../../images/tutorial-6.png';
import ParameterImage1 from '../../images/parameter-image-1.png';
import ParameterImage2 from '../../images/parameter-image-2.png';
import ParameterImage3 from '../../images/parameter-image-3.png';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import './styles.home.scss';
import { changeTypeRequest, getHomeStaticsRequest } from '../../redux/controller';
import { IStatistics } from '../../common/u-innovate/define-statistics';
import { ChangeTypeRequest } from '../../common/define-identity';

// Phần trang chủ của trang web


const Home = () => {
    const { user } = useSelectorRoot((state) => state.login);
    const [isLogin, setIsLogin] = useState<boolean>(false)
    const navigate = useNavigate();
    const mapRef = useRef<any>(null);
    const dispatch = useDispatchRoot();
    const { homeStatistics } = useSelectorRoot((state) => state.uinnovate);
    const [checkHomeStatistics, setCheckHomeStatistics] = useState<IStatistics>();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [contentModal, setContentModal] = useState<any>();
    const [positionIdStaffInChangeStartUpInnovate, setPositionIdStaffInChangeStartUpInnovate] = useState<string>('63fed40404d0683c30798350')

    const imageVariants: Variants = {
        offscreen: {
            y: 200,
            opacity: 0
        },
        onscreen: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    };
    const imageVariants2: Variants = {
        offscreen: {
            x: 200,
            opacity: 0
        },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 2
            }
        }
    };
    let dataParameter = [
        {
            image: ParameterImage1,
            numberUser: checkHomeStatistics?.numOfUsers,
            content: 'Số lượng người dùng'
        },
        {
            image: ParameterImage2,
            numberUser: checkHomeStatistics?.numOfFacilities,
            content: 'Trường đại học được đánh giá'
        },
        {
            image: ParameterImage3,
            numberUser: checkHomeStatistics?.numOfAdresses,
            content: 'Địa phương được đánh giá',
        }
    ]
    const hoverVariants = {
        hover: {
            scale: 1.1,
            opacity: 0.8,
            borderRadius: '30px'
        },
        tap: {
            scale: 0.8
        },
    };

    useEffect(() => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            setIsLogin(true);


        }
        dispatch(getHomeStaticsRequest())
    }, []);

    useEffect(() => {
        homeStatistics && setCheckHomeStatistics(homeStatistics);

    }, [homeStatistics]);
    useEffect(() => {
        if (mapRef.current) {
            const mapDoc = mapRef.current.contentWindow?.document;
            if (mapDoc) {
                const placeCard = mapDoc.querySelector('.place-card');
                if (placeCard) {
                    placeCard.style.backgroundColor = 'red';
                }
            }
        }
    }, [mapRef]);

    const handleOnClick = () => {
        if (!isLogin) {
            notification['warning']({
                message: 'Vui lòng đăng nhập tài khoản trước',
                duration: 1,
                style: {
                    width: '100%'
                }
            });
        }
        else {
            navigate('/test')
        }
    }

    const handleOnClickTool = (name: string) => {
        console.log(user);

        if (name === 'UINNOVATE')
            name = (user?.position.id !== positionIdStaffInChangeStartUpInnovate) ? 'UINNOVATE_SHORT' : 'UINNOVATE'

        if (!isLogin) {
            notification['warning']({
                message: 'Vui lòng đăng nhập tài khoản trước',
                duration: 1,
                style: {
                    width: '100%'
                }
            });
        } if (user) {
            if (user.type === name || (user.type === 'UINNOVATE_SHORT' && name === 'UINNOVATE')) {
                navigate('/test')
            }
            else {
                if (name === 'PINNOVATE') {
                    notification['warning']({
                        message: 'Vui lòng chọn Đánh giá địa phương ở phần Đăng ký để sử dụng công cụ',
                    });
                }
                if (user.type === 'UIMPACT' && name === 'UINNOVATE') {
                    console.log(name);

                    const req: ChangeTypeRequest = {
                        type: 'UINNOVATE',
                        additionalProp1: {},
                    }
                    dispatch(changeTypeRequest(req))
                    navigate('/test')
                    return;
                }

                if (user.type === 'UIMPACT' && name === 'UINNOVATE_SHORT') {
                    console.log(name);
                    const req: ChangeTypeRequest = {
                        type: 'UINNOVATE_SHORT',
                        additionalProp1: {},
                    }
                    dispatch(changeTypeRequest(req))
                    navigate('/test')
                    return;
                }

                if ((user.type === 'UINNOVATE' || user.type === 'UINNOVATE_SHORT') && name === 'UIMPACT') {
                    // notification['warning']({
                    //     message: 'Vui lòng chọn Công cụ đánh giá ở thanh công cụ để sử dụng bộ tiêu chí này',
                    // });
                    const req: ChangeTypeRequest = {
                        type: 'UIMPACT',
                        additionalProp1: {},
                    }
                    dispatch(changeTypeRequest(req))
                    navigate('/test')
                    return;
                }
                if (user.type === 'PINNOVATE') {
                    notification['warning']({
                        message: 'Vui lòng chọn Đánh giá trường đại học ở phần Đăng ký để sử dụng công cụ',
                    });
                }
                // switch (name) {
                //     case 'UINNOVATE':
                //         setContentModal('U-INNOVATE')
                //         setIsShowModal(true)
                //         break;
                //     case 'UIMPACT':
                //         setContentModal('U-IMPACT')
                //         setIsShowModal(true)
                //         break;
                //     case 'PINNOVATE':
                //         setContentModal('P-INNOVATE')
                //         setIsShowModal(true)
                // }
            }
        }


    }

    return (
        <motion.div
            className='main-home'
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
            <div className='intro-part'>
                <div className='text-of-intro'>
                    <div className='title' >Tổ chức, đơn vị của bạn đã chuẩn bị sẵn sàng cho những thách thức trong tương lai chưa? </div>
                    <div className='detail'>Viện Nghiên cứu Đổi mới và Phát triển (IID), được thành lập năm từ năm 2017, là một viện nghiên cứu dựa trên nền tảng công nghệ, dữ liệu và mạng lưới kết nối toàn cầu để theo đuổi sứ mệnh trở thành một là một tổ chức trung gian, xây dựng hệ sinh thái góp phần tạo ra sân chơi cho những học giả và ươm tạo nhân tài trẻ góp phần thúc đẩy khu vực sáng tạo và kinh doanh tạo tác động xã hội Việt Nam.</div>
                    <motion.div className='home-button'
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}>
                        <Button onClick={handleOnClick}>Đến trang đánh giá</Button>
                    </motion.div>
                </div>
                <motion.div className='image-of-intro'
                    variants={imageVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 'all' }}>
                    <img src={ImageOfIntro} alt=''
                    />
                </motion.div>
            </div>
            <div className='tool-of-web'>
                <div className="title">Công cụ đánh giá của V.innovate</div>
                <div className="subtitle">Đây là một nền tảng chuyên về đánh giá, xếp hạng các trường đại học, cao đẳng; tỉnh thành phố về đổi mới sáng tạo, khởi nghiệp và tạo tác động </div>
                <div className="lst-tool">
                    <motion.div
                        className="tool"
                        whileHover="hover"
                        whileTap="tap"
                        variants={hoverVariants}
                        onClick={() => handleOnClickTool('UINNOVATE')}
                    >
                        <div className="tool-title">U.INNOVATE</div>
                        <div className="tool-subtitle">Bộ tiêu chí đánh giá trường đại học khởi nghiệp</div>
                        <div className='btn-tool'>Bắt đầu đánh giá</div>
                    </motion.div>
                    <motion.div
                        className="tool"
                        whileHover="hover"
                        whileTap="tap"
                        variants={hoverVariants}
                        onClick={() => handleOnClickTool('UIMPACT')}
                    >
                        <div className="tool-title">U.IMPACT</div>
                        <div className="tool-subtitle">Bộ tiêu chí đánh giá trường đại học tạo tác động xã hội</div>
                        <div className='btn-tool'>Bắt đầu đánh giá</div>
                    </motion.div>
                    <motion.div
                        className="tool"
                        whileHover="hover"
                        whileTap="tap"
                        variants={hoverVariants}
                        onClick={() => handleOnClickTool('PINNOVATE')}
                    >
                        <div className="tool-title">P.INNOVATE</div>
                        <div className="tool-subtitle">Bộ tiêu chí đánh giá khởi nghiệp tại địa phương</div>
                        <div className='btn-tool'>Bắt đầu đánh giá</div>
                    </motion.div>

                </div>
            </div>
            <div className='how-to-use'>
                <div className='tutorial-image'>
                    <motion.img
                        src={HowToUse}
                        alt=''
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ cursor: 'pointer' }}
                    // variants={imageVariants2}
                    // initial="offscreen"
                    // whileInView="onscreen"
                    // viewport={{ once: true, amount: 'all' }}
                    />
                </div>
                <div className='content'>
                    <div className='title'>Bạn nhận được gì từ V.innovate</div>
                    {/* <div className='subtitle'>Lorem ipsum dolor sit amet consectetur. Maecenas aenean neque at id erat. Lacus vel est luctus arcu risus ultricies pharetra bibendum.</div> */}
                    <div className='step-lst'>
                        <div className="row-item">
                            <motion.div className="item-lst"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <div className="item-title">TỰ ĐÁNH GIÁ</div>
                                <div className="item-sub-title">Mức độ phát triển của hệ sinh thái của tổ chức, địa phương theo tiêu chuẩn quốc tế với cách thức dễ triển khai</div>
                                <img className='item-img' src={HowToUse1} alt="" />
                            </motion.div>
                            <motion.div className="item-lst"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}>
                                <div className="item-title">QUAN SÁT</div>
                                <div className="item-sub-title">Quan sát được vị trí của hệ sinh thái tổ chức, địa phương mình trong đánh giá của nhiều bên liên quan</div>
                                <img className='item-img' src={HowToUse2} alt="" />
                            </motion.div>
                        </div>
                        <div className="row-item">
                            <motion.div className="item-lst"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}>
                                <div className="item-title">XÁC ĐỊNH</div>
                                <div className="item-sub-title">Xác định được phương diện nổi trội và cần cải thiện để xây dựng chiến lược phát triển hệ sinh thái</div>
                                <img className='item-img' src={HowToUse3} alt="" />
                            </motion.div>
                            <motion.div className="item-lst"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}>
                                <div className="item-title">NHẬN BÁO CÁO </div>
                                <div className="item-sub-title">Báo cáo tóm tắt so sánh và quan sát theo thời gian của hệ sinh thái</div>
                                <img className='item-img' src={HowToUse4} alt="" />
                            </motion.div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="parameter-lst">
                {dataParameter && dataParameter.map((item, index) => (
                    <div key={index} className="parameter">
                        <motion.img className='parameter-image' src={item.image} alt="" whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }} />
                        <div className="parameter-content">
                            <div className="number-user">{item.numberUser}</div>
                            <div className="content">{item.content}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='tutorial'>
                <div className="tutorial-title">Hướng dẫn sử dụng V.innovate</div>
                {/* <div className="tutorial-sub-title">Lorem ipsum dolor sit amet consectetur. Maecenas aenean neque at id erat. Lacus vel est luctus arcu risus ultricies pharetra bibendum.</div> */}
                <div className="first-tutorial">
                    <div className="f-t-left">
                        <motion.div className="f-t-l-item"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial1} alt='' />
                            <div className="f-t-l-t-content">Bạn cần tạo tài khoản của mình trên V.innovate</div>
                        </motion.div>
                        <motion.div className="f-t-l-item" whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial2} alt='' />
                            <div className="f-t-l-t-content">Bạn có thể có cái nhìn tổng quan về các tiêu chí và phương pháp của V.innovate</div>
                        </motion.div>
                        <motion.div className="f-t-l-item" whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial3} alt='' />
                            <div className="f-t-l-t-content">Bạn tiến hành tự đánh giá thông qua các bước trên nền tảng V.innovate</div>
                        </motion.div>
                        <motion.div className="f-t-l-item" whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial4} alt='' />
                            <div className="f-t-l-t-content">Bạn có thể đọc báo cáo về cơ sở giáo dục của mình trên V.innovate hoặc download báo cáo về máy, hoặc gửi email báo cáo.</div>
                        </motion.div>
                    </div>
                    <div className="f-t-right">
                        <motion.img src={FirstTutorial} alt=''
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }} />
                    </div>
                </div>
                <div className="first-tutorial second-tutorial">
                    <div className="f-t-right" style={{ marginTop: '-200px' }}>
                        <motion.img src={SecondTutorial} alt=''
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }} />
                    </div>
                    <div className="f-t-left">
                        <motion.div className="f-t-l-item"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial5} alt='' />
                            <div className="f-t-l-t-content">Bạn có thể quay lại để tham khảo sự thay đổi kết quả tự đánh giá, và kết quả so sánh sau một thời gian.</div>
                        </motion.div>
                        <motion.div className="f-t-l-item long-item" whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.95 }}>
                            <img className='f-t-l-t-icon' src={Tutorial6} alt='' />
                            <div className="f-t-l-t-content">Ngoài đưa ra các đánh giá và sự quan tâm của các doanh nghiệp khởi nghiệp qua U.innovate, V.innovate còn giúp nhận định sự tác động của khởi nghiệp đối với các mục tiêu SDG Phát triển bền vững thông qua U.impact và P.innovate với nhiệm vụ đưa ra các đánh giá, nhận định, độ phủ quan tâm về lĩnh vực Khởi nghiệp tại các tỉnh, thành phố thông qua bản đồ các doanh nghiệp khởi nghiệp</div>
                        </motion.div>

                    </div>

                </div>
            </div>
            {/* <div className='map-user'>
                <div className="map-title">Bản đồ người sử dụng V.innovate</div>
                <iframe
                    className="gmap_iframe"
                    id='gmap'
                    ref={mapRef}
                    src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=176 P. Thái Hà, Trung Liệt, Đống Đa, Hà Nội&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                />
            </div> */}
            {/* <iframe
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=5th Floor, Vietnam Academy of Social Sciences Building, 176 Thai Ha, Dong Da, Hanoi&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            </iframe> */}

            {/* <div className='our-partner'>
                <div className='title'>Đối tác của chúng tôi V.innovate</div>
                <div className="sub-title">Lorem ipsum dolor sit amet consectetur. Maecenas aenean neque at id erat. Lacus vel est luctus arcu risus ultricies pharetra bibendum.</div>
                <div className='partner-lst'>
                    <div className='item'>1</div>
                    <div className='item'>2</div>
                    <div className='item'>3</div>
                    <div className='item'>4</div>
                    <div className='item'>5</div>
                    <div className='item'>6</div>
                </div>
            </div> */}

            <Modal
                className={contentModal}
                title={'GIỚI THIỆU VỀ ' + contentModal}
                visible={isShowModal}
                onCancel={() => setIsShowModal(false)}
                footer={null}
                width={1000}
            >
                {contentModal === 'U-INNOVATE' &&
                    <div className='main-modal'>
                        <div className='detail'>U.innovate là công cụ tự đánh giá, được truyền cảm hứng từ mô hình đại học khởi nghiệp của OECD. Các trường đại học thông qua U.innovate có thể quan sát được các điểm mạnh, điểm yếu trong hệ sinh thái đổi mới sáng tạo và khởi nghiệp của trường mình. Đồng thời có thể so sánh và đối chiếu về sự phát triển của nhà trường cùng với thời gian, cũng như so sánh với trung bình những trường khác tại Việt Nam.</div>
                        <div className='detail'>Để bắt đầu tự đánh giá, hãy chọn một trong 8 tiêu chí.  Trong mỗi khía cạnh, các câu lệnh đã được thiết kế để bạn có thể đánh giá chúng theo 3 mức độ (không đồng ý, đồng ý một phần, hoàn toàn đồng ý). Vui lòng sử dụng thanh trượt bên dưới các câu để ghi điểm.</div>
                    </div>
                }
                {contentModal === 'U-IMPACT' &&
                    <div className='main-modal'>
                        <div className='detail'>U.impact là công cụ tự đánh giá mức độ tác động xã hội của trường đại học thông qua 17 mục tiêu phát triển bền vững thuộc Liên Hợp Quốc. Các trường đại học thông qua U.impact có thể quan sát được các tác động của mình cho cộng đồng, xã hội.</div>
                        <div className='detail'>
                            Phương pháp đánh giá
                            <ul>
                                <li>Bộ tiêu chí được truyền cảm hứng từ phương pháp của Tạp chí Times</li>
                                <li>Mỗi nhà trường xác định 03 mục tiêu phát triền bền vững (SDG) dược coi là cốt lõi mà nhà trường theo đuổi</li>
                                <li>Sau khi lựa chọn, một bảng hỏi với các tiêu chí giúp đánh giá mức độ tạo tác động xã hội của Nhà trường với 04 SDG bao gồm: SDG 17 (quan hệ đối tác bền vững, là bắt buộc với tất cả các trường đại học) và 03 SDG đã lựa chọn ở trên</li>
                                <li>Bảng hỏi không đòi hỏi Nhà trường phải cung cấp dữ liệu mà tập trung vào tự đánh giá xem mức độ hiểu biết và thực hiện ở chiều rộng và chiều sâu với 5 cấp độ</li>
                                <li>Kết quả đánh giá Nhà trường sẽ quan sát được từng mảng thực hiện của mình dang ở mức độ nào, so sánh các trường khác trên thị trường, so sánh với chính mình theo thời gian</li>
                            </ul>
                        </div>
                    </div>
                }

                {contentModal === 'P-INNOVATE' &&
                    <div className='main-modal'>
                        <div className='detail'>P.innovate là công cụ tự đánh giá cho các cộng đồng khởi nghiệp tại từng tỉnh/thành phố có thể quan sát được các chính sách, nguồn lực, văn hoá tác động tới hệ sinh thái khởi nghiệp của mình. Đồng thời có thể so sánh và đối chiếu về sự phát triển của hệ sinh thái tại địa phương mình cùng với thời gian, cũng như so sánh với trung bình những tỉnh thành khác tại Việt Nam.</div>
                        <div className='detail'>
                            Phương pháp đánh giá
                            <ul>
                                <li>Để có một hệ sinh thái khởi nghiệp mạnh mẽ và là cái nôi ươm mầm các doanh nghiệp khởi nghiệp sáng tạo, cần có 3 nhóm cấu thành cơ bản sau: thứ nhất, cần có các nguyên tắc về phát triển hoạt động khởi nghiệp; thứ hai, cần có các chủ thể vận hành trong hệ sinh thái và thứ ba, các cầu phần cơ sở tạo thành hệ sinh thái</li>
                                <li>Bộ tiêu chí đánh giá của p.innovate gồm có 10 nguyên tắc vận hành, 12 chủ thể và 12 cấu phần của hệ sinh thái. </li>
                                <li>Các địa phương sẽ tự đánh giá, được đánh giá về mức độ phát triển trên thang điểm từ 1 đến 10 của hệ sinh thái khởi nghiệp của mình ở thời điểm hiện nay và 5 năm trước đây, để quan sát mức độ phát triển của mình.  </li>
                            </ul>
                        </div>
                    </div>
                }
            </Modal>

        </motion.div>
    )
}

export default Home