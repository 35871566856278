import React from 'react'
import { Chart, ChartBar, ChartDonut, ChartGroup, ChartVoronoiContainer } from '@patternfly/react-charts';
import { Breadcrumb, Button, Input, Pagination, Select, Spin } from "antd";
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './style.userstatistics.scss'
import Logo from '../../images/Logo.png';
// import ReactApexChart from 'react-apexcharts';

import { PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
    // scales,
} from 'chart.js';
import { getUserInfoRequest } from '../../redux/controller';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import ResultAPI from '../../api/results/results.api';
import SYSTEM_CONSTANTS from '../../common/constants';
import PolarAreaChart from '../../components/Chart/PolarAreaChart';
import DoughnutChart from '../../components/Chart/DoughnutChart';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
// ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const lstColor = ['rgba(239, 83, 80, 0.7)', 'rgb(255, 167, 38, 0.7)', 'rgb(255, 213, 79, 0.7)', 'rgb(102, 187, 106, 0.7)', 'rgb(66, 165, 245, 0.7)', 'rgb(92, 107, 192, 0.7)', 'rgb(171, 71, 188, 0.7)', 'rgb(236, 64, 122, 0.7)']
const UserStatistics = () => {

    const [options, setOptions] = useState<any>({})
    const [optionsDonut, setOptionsDonut] = useState<any>({})
    const [yearSelected, setYearSelected] = useState<string>('2024')
    const [data, setData] = useState<any[]>([])
    const [dataDonut, setDataDonut] = useState<any>(null)
    const [userType, setUserType] = useState<string>('')
    const dispatch = useDispatchRoot()
    const { user } = useSelectorRoot((state) => state.login);
    const [systemStatistics, setSystemStatistics] = useState<any>()
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [lstSeries, setLstSeries] = useState<any>()
    const [lstData, setLstData] = useState<any>(null)
    // Kiểm tra khi màn hình thay đổi kích thước
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        console.log(windowSize);

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    useEffect(() => {
        user && fetchDataSystemStatistics();
    }, [yearSelected])

    useEffect(() => {
        let type = localStorage.getItem('userType') ? localStorage.getItem('userType') : '';
        let token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
        if (type) {
            type = type.slice(1);
            type = type.slice(0, type.length - 1);

            if (type === 'UINNOVATE_SHORT') {
                type = 'UINNOVATE';
            }
            setUserType(type);

        }
        if (token) {
            token = token.slice(1);
            token = token.slice(0, token.length - 1);
            dispatch(getUserInfoRequest(token))
        }
    }, [])

    useEffect(() => {
        console.log(user);

        user &&
            fetchDataSystemStatistics();
    }, [user])


    useEffect(() => {
        console.log(data);
        if (data.length === 0) return

        if (userType === 'UINNOVATE' || userType === 'UINNOVATE_SHORT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.setOfQuestionDes)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 15;
                                case 1:
                                    return 55;
                                case 2:
                                    return 100;
                                case 3:
                                    return 155;
                                case 4:
                                    return 200;
                                case 5:
                                    return 245;
                                case 6:
                                    return 290;
                                case 7:
                                    return 335;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [4, 5, 5, 6, 5, 5, 5, 6],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
        }

        else if (userType === 'UIMPACT') {
            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 140;
                                case 2:
                                    return 230;
                                case 3:
                                    return 320;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length, series[3].length],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
        }

        else if (userType === 'PINNOVATE') {

            const dataSort = data.sort((a: any, b: any) => {
                if (a.criteriaId < b.criteriaId) {
                    return -1;
                }
                if (a.criteriaId > b.criteriaId) {
                    return 1;
                }
                return 0;
            });
            console.log(dataSort[0].criteriaName);


            const series = dataSort.map((item: any) => item.setOfQuestions)
            console.log(series[0].length);

            // Cho series thành mảng 1 chiều
            let series1D: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    series1D.push(Math.round(item1.average * 10) / 10)
                })
            })
            console.log(series1D);
            setLstSeries(series1D)

            let labels: any[] = []
            series.forEach((item) => {
                item.forEach((item1: any) => {
                    labels.push(item1.name)
                })
            })
            console.log(labels);


            // Tạo ra mảng màu theo số lượng series, mỗi series[0], series[1] có 1 màu, còn serti[0][0] sẽ trùng với màu của series[0]
            let colors: string[] = []
            series.forEach((item, index) => {
                // Lấy ra màu ngẫu nhiêu để cho vào mảng colors
                item.forEach(() => {
                    // colors.push('#FF0000')
                    // colors sẽ lấy ra màu ngẫu nhiên
                    colors.push(lstColor[index])
                })
            })
            console.log(colors);

            const options = {
                responsive: true,
                scales: {
                    r: {
                        suggestedMin: 0,
                        suggestedMax: 5,
                        grid: {
                            circular: true,
                            colors: '#000',
                        },
                        angleLines: {
                            display: true,
                            color: '#00000',
                            lineWidth: 1,
                        },
                        ticks: {
                            stepSize: 1,
                            beginAtZero: true,
                            backdropColor: 'transparent',
                            font: {
                                size: 19,
                            },
                            color: '#000',
                        },
                        lineArc: true,

                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },

                    dataLabels: {
                        // color: '#000',
                        display: false,

                        // formatter: (value: any, context: any) => {
                        //     return ''; // Display the value in the label
                        // },
                    },
                },
                cutout: '50%',
                layout: {
                    padding: 5
                },
                hover: {
                    mode: 'nearest'
                },
                animation: {
                    duration: 2000
                },
                tooltips: {
                    enabled: true,
                },
            };

            const optionsDonut = {
                cutout: '80%', // Adjust the size of the inner hole
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        color: (context: any) => {
                            return lstColor[context.dataIndex];
                        },
                        rotation: (context: any) => {
                            switch (context.dataIndex) {
                                case 0:
                                    return 55;
                                case 1:
                                    return 170;
                                case 2:
                                    return 300;
                                default:
                                    return 0;
                            }
                        },
                        display: true,

                        formatter: (value: any, context: any, index: any) => {
                            console.log(context.dataIndex);
                            console.log(dataSort[context.dataIndex].criteriaName);
                            //Kiểm tra nếu
                            let label = dataSort[context.dataIndex].criteriaName;
                            if (label.length > 30) {
                                label = label.substring(0, 30) + '...';
                            }
                            return label; // Display the value in the label
                        },
                        font: {
                            size: windowSize[0] > 400 ? 12 : 6, // Set the font size for data labels
                            fontWeight: 'bold',
                        },
                    },
                },
            }

            const dataTmp = {
                labels: labels,
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: series1D,
                        backgroundColor: colors,
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 50,
                        indexAxis: 'r',
                    },
                ],
            }

            const dataDonutTmp = {
                labels: dataSort.map((item: any) => item.criteriaName),
                datasets: [
                    {
                        label: 'Điểm: ',
                        data: [series[0].length, series[1].length, series[2].length],
                        backgroundColor: ['#ffffff', '#ffffff', '#ffffff'],
                        borderColor: '#000',
                        borderWidth: 1,
                        innerRadius: 90,
                        indexAxis: 'r',
                    },
                ],
            }

            console.log(dataDonutTmp);


            setOptions(options)
            setOptionsDonut(optionsDonut)
            setDataDonut(dataDonutTmp)
            setLstData(dataTmp)
        }
    }, [data])

    useEffect(() => {
        console.log(lstData);
        console.log(dataDonut);
        
        

    }, [lstData, dataDonut])


    const fetchDataSystemStatistics = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.replace(/"/g, '');
        }

        console.log(checkLogin);

        await axios.post(`${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_DETAIL_USER}?type=${user?.type}`,
            {

            },
            {
                headers: {
                    acccept: 'application/json',
                    Authorization: `Bearer ${checkLogin}`
                }
            })
            .then((res) => {
                console.log(res.data.data);
                const dataSort = res.data.data.sort((a: any, b: any) => {
                    if (a.criteriaId < b.criteriaId) {
                        return -1;
                    }
                    if (a.criteriaId > b.criteriaId) {
                        return 1;
                    }
                    return 0;
                });

                console.log(dataSort);


                setData(dataSort)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <Spin spinning={!lstData || !dataDonut} size='large'>
            <div className='main-statistics'>
                <div className='user-statistics'>
                    <div className='usermanagement-title'>
                        {(userType === 'UINNOVATE' || userType === 'UIMPACT') && <h5 className={`${userType}`}> Kết quả đánh giá trường đại học khởi nghiệp</h5>}
                        {userType === '6' && <h5 className={`${userType}`}> Kết quả đánh giá địa phương</h5>}
                        {((userType === 'UINNOVATE' || userType === 'UIMPACT') && user) &&
                            <div className='subtitle'>Trường được đánh giá: <b>{user.facility.name}</b></div>
                        }
                        {(userType === 'PINNOVATE' && user && user.address) &&
                            <div className='subtitle'>Địa phương được đánh giá: <b>{user.address.name}</b></div>
                        }
                        <div className='subtitle'>Tổng số người đã tham gia đánh giá:<b> {systemStatistics?.data.totalOfResult}</b></div>
                    </div>
                    <div className='usermanagement-filter'>
                        <Select
                            placeholder="Chọn năm"
                            defaultValue={'2024'}
                            onChange={(value) => setYearSelected(value)}
                        >
                            <Select.Option value="2020">2020</Select.Option>
                            <Select.Option value="2021">2021</Select.Option>
                            <Select.Option value="2022">2022</Select.Option>
                            <Select.Option value="2023">2023</Select.Option>
                            <Select.Option value="2024">2024</Select.Option>
                        </Select>
                    </div>

                    <div className='charts'>
                        <div
                            className='title-chart'
                        >
                            Thống kê báo cáo theo năm
                        </div>
                        {((lstData && dataDonut)) &&
                            <div className="charts">
                                <div className='chart'>
                                    <div className="chart-polar">
                                        <PolarAreaChart data={lstData} options={options} />
                                        <div className="logo-chart">
                                            <img src={Logo} alt="" />
                                        </div>
                                    </div>
                                    <div className="chart-dough">
                                        <DoughnutChart data={dataDonut} options={optionsDonut} />
                                    </div>
                                    {/* <SunburstChart data={data1} /> */}
                                </div>

                                {/* <div className='labels'>
                                {
                                    systemStatistics?.data?.listAverageOfCriterias?.map((item: any, index: any) => {
                                        return (
                                            <div className='labels__item' key={index}>
                                                <div className='labels__item__color' style={{ backgroundColor: lstColor[index] }}></div>
                                                <div className='labels__item__text'>
                                                    {item.criteriaName}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div> */}
                            </div>
                        }

                    </div>
                </div >
            </div>
        </Spin>
    )
}

export default UserStatistics
