/* eslint-disable new-parens */
import HttpClient from "../http-client";
import SYSTEM_CONSTANTS from "../../common/constants";
import axios from "axios";

import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from "rxjs/operators";
import { ICriteria } from "../../common/u-innovate/define-criteria";
import { GetAllQuestionByCriteriaIdRequest, IGetAllQuestionsByCriteriaResponse } from "../../common/u-innovate/define-question";
import { AllTotalResult, Answer, PostAnswerRequest, PostResultRequest, PutAnswerRequest, PutResultRequest, Result, TotalResultByCriteria } from "../../common/u-innovate/define-results";

export default class AnswerAPI {
    static host = 'https://api.vinnovate.vn';

    static PostAnswer(req: any): Observable<any | null> {
        const api = `${AnswerAPI.host}/${SYSTEM_CONSTANTS.API.ANSWER.POST_ANSWER}`;
        return HttpClient.post(api, req).pipe(
            map((res) => res as Result || null, catchError((error) => new Observable))
        );
    }

    static PutAnswer(questionId: string, req: any): Observable<any | null> {
        const api = `${AnswerAPI.host}/${SYSTEM_CONSTANTS.API.ANSWER.PUT_ANSWER}/${questionId}`;
        return HttpClient.put(api, req).pipe(
            map((res) => res as Result || null, catchError((error) => new Observable))
        );
    }


}