import React from 'react'

const OverviewAdmin = () => {
    return (
        <div>
            Admin
        </div>
    )
}

export default OverviewAdmin
