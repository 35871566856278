import { ArrowLeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Checkbox, Form, Input, Select, Spin, Steps, message, notification } from 'antd';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.scss';
import { LoginRequest, RegisterRequest } from '../../common/define-identity';
import { IAddresses } from '../../common/u-innovate/define-addresses';
import { IFacilities, IFacilitiesList } from '../../common/u-innovate/define-facilities';
import { IPosition } from '../../common/u-innovate/define-position';
import LoginImage2 from '../../images/login-image-2.png';
import LoginImage from '../../images/login-image.png';
import ForgotPassWordImage from '../../images/forgot-password-image.png';
import { getAllAddressesRequest, getAllFacilitiesByDescriptionRequest, getAllPositionsRequest } from '../../redux/controller';
import { checkEmailRequest, forgotPasswordRequest, getUserInfoRequest, loginRequest, registerRequest, resetPasswordRequest } from '../../redux/controller/login.slice';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import ActiveAccountModel from '../ActiveAccount/ActiveAccountModel';
import './login.scss';
interface MyProps {
    isLogin?: boolean
}

const { Option } = Select;

const hoverVariants = {
    hover: {
        scale: 1.1,
        borderRadius: '30px'
    },
    tap: {
        scale: 0.8
    },
};
// Phần đăng nhập / đăng ký của trang web
const Login = (props: MyProps) => {

    const [form] = Form.useForm();
    const [isLogin, setIsLogin] = useState(true); // Biến kiểm tra có đang ở trang đăng nhập hay đăng ký
    const dispatch = useDispatchRoot();
    const navigate = useNavigate();
    const { tokenLogin, isExistEmail, registerSuccess, changePasswordSuccess, user, loadingRegister } = useSelectorRoot((state) => state.login);

    const { positionsLst, facilitiesLst, facilitiesLstByDescription, addressesLst, positonUniversityLst, positonLocalLst, } = useSelectorRoot((state) => state.uinnovate);

    const [lstPosition, setLstPosition] = useState<IPosition[]>([]); // Biến lưu trữ danh sách vai trò
    const [lstPositonUniversity, setLstPositonUniversity] = useState<IPosition[]>([]); // Biến lưu trữ danh sách vai trò của trường đại học
    const [lstPositonLocal, setLstPositonLocal] = useState<IPosition[]>([]); // Biến lưu trữ danh sách vai trò của cơ sở đào tạo
    const [lstFacility, setLstFacility] = useState<IFacilities[]>([]); // Biến lưu trữ danh sách cơ sở đào tạo
    const [lstFacilityByDescription, setLstFacilityByDescription] = useState<IFacilitiesList>(); // Biến lưu trữ danh sách cơ sở đào tạo
    const [lstAddresses, setLstAddresses] = useState<IAddresses[]>([]);

    const [userName, setUserName] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');
    const [userPassword, setUserPassword] = useState<string>('');
    const [userConfirmPassword, setUserConfirmPassword] = useState<string>('');
    const [userFacilityId, setUserFacilityId] = useState<string>('');
    const [userPositionId, setUserPositionId] = useState<string>('');
    const [userLocalId, setUserLocalId] = useState<string>('');
    const [userPositionLocalId, setUserPositionLocalId] = useState<string>('');
    const [positionIdStudent, setPositionIdStudent] = useState<string>('63bfc266919bbb3754b7162a')
    const [positionIdOldStudent, setPositionIdOldStudent] = useState<string>('65ee710058d0144d18a4a933')
    const [positionIdStaffInChange, setPositionIdStaffInChange] = useState<string>('63fed41004d0683c30798352')
    const [positionIdStaffInChangeStartUp, setPositionIdStaffInChangeStartUp] = useState<string>('63ff07c04b0a045eb0d8c39d')
    const [positionIdStaffInChangeStartUpInnovate, setPositionIdStaffInChangeStartUpInnovate] = useState<string>('63fed40404d0683c30798350')
    const [userBelongTo, setUserBelongTo] = useState<string>(''); // Biến lưu trữ giá trị của vai trò
    const [userRoleInFacility, setUserRoleInFacility] = useState<string>(''); // Biến lưu trữ giá trị của vai trò
    const [userResponsibleField, setUserResponsibleField] = useState<string>(''); // Biến lưu trữ giá trị của vai trò

    const [isMailEdu, setIsMailEdu] = useState<boolean>(false);
    const [checkFacility, setCheckFacility] = useState<number>(0);
    const [checkClickTypeOfFacility, setCheckClickTypeOfFacility] = useState<boolean>(false);
    const [checkClickFacility, setCheckClickFacility] = useState<boolean>(false);
    const [checkClickPosition, setCheckClickPosition] = useState<boolean>(false);
    const [checkBeginFrom, setCheckBeginFrom] = useState<boolean>(false);
    const [checkClickUniversityReview, setCheckClickUniversityReview] = useState<boolean>(false);
    const [checkClickLocalReview, setCheckClickLocalReview] = useState<boolean>(false);
    const [currentUniversity, setCurrentUniversity] = useState(0);  // Biến gán giá trị đang ở bước bao nhiêu của trang đăng ký
    const [currentLocal, setCurrentLocal] = useState(0);  // Biến gán giá trị đang ở bước bao nhiêu của trang đăng ký

    const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState<boolean>(false);

    const [resendDisabled, setResendDisabled] = useState(true);
    const [countdown, setCountdown] = useState(60);

    const [EmailForgotPassword, setEmailForgotPassword] = useState<string>('');
    const [CodeForgotPassword, setCodeForgotPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // Thực hiện lấy vai trò và cơ sở đào tạo của user
    useEffect(() => {
        if (!isLogin) {
            // dispatch(getAllFacilitiesRequest());
            dispatch(getAllFacilitiesByDescriptionRequest());
            dispatch(getAllPositionsRequest());
            dispatch(getAllAddressesRequest());
        }
    }, [isLogin])

    // Thực hiện gán giá trị cơ sở đào tạo
    useEffect(() => {
        setLstFacility(JSON.parse(JSON.stringify(facilitiesLst)));
    }, [facilitiesLst]);

    // Thực hiện gán giá trị cơ sở đào tạo
    useEffect(() => {
        facilitiesLstByDescription && setLstFacilityByDescription(JSON.parse(JSON.stringify(facilitiesLstByDescription)));
        console.log(facilitiesLstByDescription);
    }, [facilitiesLstByDescription]);

    useEffect(() => {
        addressesLst && setLstAddresses(JSON.parse(JSON.stringify(addressesLst)));
        console.log(addressesLst);
    }, [addressesLst]);
    // Thực hiện gán giá trị vai trò
    useEffect(() => {
        let studentPosition = positonUniversityLst.filter((item: IPosition) => item.description === "Sinh viên");
        let teacherPosition = positonUniversityLst.filter((item: IPosition) => item.description === "Giảng viên");
        let officePosition = positonUniversityLst.filter((item: IPosition) => item.description === "Cán bộ");

        let lstPosition = [...studentPosition, ...teacherPosition, ...officePosition];

        console.log(positonUniversityLst);


        setLstPosition(JSON.parse(JSON.stringify(positionsLst)));
        setLstPositonUniversity(JSON.parse(JSON.stringify(lstPosition)));
        setLstPositonLocal(JSON.parse(JSON.stringify(positonLocalLst)));
        console.log(positonUniversityLst);
        console.log(positonLocalLst);

    }, [positionsLst]);

    // Thực hiện nếu đã đăng nhập thành công, trở về trang chủ
    useEffect(() => {
        if (tokenLogin) {
            dispatch(getUserInfoRequest(tokenLogin));
            // navigate("/");
        }
    }, [tokenLogin])

    useEffect(() => {
        console.log(user);

        if (user) {
            if (user?.role === 'admin') {
                navigate("/user-management");
            }
            else {
                navigate("/");
            }
        }
    }, [user])

    // Thực hiện chuyển đến trang tiếp theo nếu thông tin hợp lệ
    useEffect(() => {
        if (!isExistEmail) {
            if (checkClickUniversityReview)
                setCurrentUniversity(currentUniversity + 1);
            if (checkClickLocalReview)
                setCurrentLocal(currentLocal + 1);
        }
    }, [isExistEmail])

    // Thực hiện khi đăng ký thành công
    useEffect(() => {
        if (registerSuccess) {
            setIsLoading(false);
            setIsLogin(!isLogin)
            // message.success('Email xác nhận đã gửi!');
        }
    }, [registerSuccess])

    // Thực hiện khi đăng nhập thành công
    useEffect(() => {
        if (checkClickUniversityReview) {
            if (userEmail && !userEmail.includes("edu.vn")) {
                notification['info']({
                    message: 'Email cung cấp không có đuôi edu.vn',
                    description: 'Bạn không thể lựa chọn vai trò cán bộ/giảng viên/cán bộ chuyên trách nếu không cung cấp email có đuôi edu.vn',
                    duration: 3,
                    style: {
                        width: '50%',
                        marginTop: '-50px'
                    }
                });
            }
            if (userEmail && userEmail.includes("edu.vn")) setIsMailEdu(true);

        }
        else if (checkClickLocalReview) {
            if (userEmail && !userEmail.includes("gov.vn")) {
                notification['info']({
                    message: 'Email cung cấp không có đuôi gov.vn',
                    description: 'Bạn không thể lựa chọn vai trò cán bộ chuyên trách nếu không cung cấp email có đuôi gov.vn',
                    duration: 3,
                    style: {
                        width: '50%',
                        marginTop: '-50px'
                    }
                });
            }
            if (userEmail && userEmail.includes("gov.vn")) setIsMailEdu(true);
        }
        console.log(isMailEdu);
    }, [userEmail])

    // Hàm thực hiện lưu thông tin của trang đầu tiên của đăng ký
    const handleClickFirstStep = async (res: any): Promise<any> => {
        console.log(res);
        setUserName(res.userName);
        setUserEmail(res.userEmail);
        setUserPassword(res.userPassword);
        setUserConfirmPassword(res.userConfirmPassword);
        dispatch(checkEmailRequest(res.userEmail));
    }

    // Hàm thực hiện check khi click vào select 
    const handleTypeOfFacilityVisibleChange = (visible: boolean) => {
        setCheckClickTypeOfFacility(visible);
    }

    // Hàm thực hiện check khi click vào select của trường đại học / cao đẳng
    const handleFacilityVisibleChange = (visible: boolean) => {
        setCheckClickFacility(visible);
    }

    // Hàm thực hiện check khi click vào select của vị trí
    const handlePositionVisibleChange = (visible: boolean) => {
        setCheckClickPosition(visible);
    }

    const handleBelongToChange = (val: any) => {
        setUserBelongTo(val.target.value);
    }

    const handleRoleInFacilityChange = (val: any) => {
        setUserRoleInFacility(val.target.value);
    }

    const handleResponsibleFieldChange = (val: any) => {
        setUserResponsibleField(val.target.value);
    }
    // Hàm thực hiện thay đổi thông tin nhập khi chọn đại học / cao đẳng 
    const handleOnChangeTypeOfFacility = (val: string) => {
        if (val === '1') {
            setCheckFacility(1)
        }
        if (val === '2') {
            setCheckFacility(2)
        }
        console.log(val);

    }

    // Hàm thực hiện lưu thông tin của trang thứ 2 của đăng ký
    const handleClickSecondStep = async (res: any): Promise<any> => {
        console.log(res);
        if (checkClickUniversityReview) {

            let userFacilityId = "";
            let userPositionId = "";

            if (checkFacility === 1)
                userFacilityId = (res.userFacilityUniversitiesId);
            if (checkFacility === 2)
                userFacilityId = (res.userFacilityCollegesId);
            userPositionId = (res.userPositionId)

            let type = "";
            if (userPositionId !== positionIdStaffInChange && userPositionId !== positionIdStaffInChangeStartUpInnovate) {
                type = "UINNOVATE_SHORT";
            }
            if (userPositionId === positionIdStaffInChangeStartUpInnovate) {
                type = "UINNOVATE";
            }
            else if (userPositionId === positionIdStaffInChange) {
                type = "UIMPACT";
            }
            if (userPositionLocalId) {
                type = "PINNOVATE";
            }

            if (userPositionId) {
                const req: RegisterRequest = {
                    "email": userEmail,
                    "password": userPassword,
                    "confirmPassword": userConfirmPassword,
                    "name": userName,
                    "phone": "string",
                    "belongTo": 'string',
                    "roleInFacility": 'string',
                    "responsibleField": 'string',
                    "address": "string",
                    "type": type,
                    "addressId": userLocalId,
                    "facilityId": userFacilityId,
                    "positionId": userPositionId,
                    "additionalProp1": {}
                };
                dispatch(registerRequest(req));
            }
            else {
                const req: RegisterRequest = {
                    "email": userEmail,
                    "password": userPassword,
                    "confirmPassword": userConfirmPassword,
                    "name": userName,
                    "phone": "string",
                    "belongTo": 'string',
                    "roleInFacility": 'string',
                    "responsibleField": 'string',
                    "address": "string",
                    "type": type,
                    "addressId": userLocalId,
                    "facilityId": userFacilityId,
                    "positionId": userPositionLocalId,
                    "additionalProp1": {}
                };
                dispatch(registerRequest(req));
            }
            // setCurrentUniversity(currentUniversity + 1);
        }
        else if (checkClickLocalReview) {

            let userLocalId = "";
            let userPositionLocalId = "";
            userLocalId = (res.userLocalId);
            userPositionLocalId = (res.userPositionLocalId);

            let type = "";
            if (userPositionId !== positionIdStaffInChange) {
                type = "UINNOVATE";
            }
            else if (userPositionId === positionIdStaffInChange) {
                type = "UIMPACT";
            }
            if (userPositionLocalId) {
                type = "PINNOVATE";
            }

            if (userPositionId) {
                const req: RegisterRequest = {
                    "email": userEmail,
                    "password": userPassword,
                    "confirmPassword": userConfirmPassword,
                    "name": userName,
                    "phone": "string",
                    "belongTo": 'string',
                    "roleInFacility": 'string',
                    "responsibleField": 'string',
                    "address": "string",
                    "type": type,
                    "addressId": userLocalId,
                    "facilityId": userFacilityId,
                    "positionId": userPositionId,
                    "additionalProp1": {}
                };
                dispatch(registerRequest(req));
            }
            else {
                const req: RegisterRequest = {
                    "email": userEmail,
                    "password": userPassword,
                    "confirmPassword": userConfirmPassword,
                    "name": userName,
                    "phone": "string",
                    "belongTo": 'string',
                    "roleInFacility": 'string',
                    "responsibleField": 'string',
                    "address": "string",
                    "type": type,
                    "addressId": userLocalId,
                    "facilityId": userFacilityId,
                    "positionId": userPositionLocalId,
                    "additionalProp1": {}
                };
                dispatch(registerRequest(req));
            }

            // setCurrentLocal(currentLocal + 1);

            // onFinishRegister()
        }
    }

    // Hàm thực hiện khi đã hoàn thành form đăng ký
    const onFinishRegister = async (): Promise<any> => {

        let type = "";
        if (userPositionId !== positionIdStaffInChange) {
            type = "UINNOVATE";
        }
        else if (userPositionId === positionIdStaffInChange) {
            type = "UIMPACT";
        }
        if (userPositionLocalId) {
            type = "PINNOVATE";
        }
        setIsLoading(true);
        if (userPositionId) {
            const req: RegisterRequest = {
                "email": userEmail,
                "password": userPassword,
                "confirmPassword": userConfirmPassword,
                "name": userName,
                "phone": "string",
                "belongTo": userBelongTo,
                "roleInFacility": userRoleInFacility,
                "responsibleField": userResponsibleField,
                "address": "string",
                "type": type,
                "addressId": userLocalId,
                "facilityId": userFacilityId,
                "positionId": userPositionId,
                "additionalProp1": {}
            };
            dispatch(registerRequest(req));
        }
        else {
            const req: RegisterRequest = {
                "email": userEmail,
                "password": userPassword,
                "confirmPassword": userConfirmPassword,
                "name": userName,
                "phone": "string",
                "belongTo": userBelongTo,
                "roleInFacility": userRoleInFacility,
                "responsibleField": userResponsibleField,
                "address": "string",
                "type": type,
                "addressId": userLocalId,
                "facilityId": userFacilityId,
                "positionId": userPositionLocalId,
                "additionalProp1": {}
            };
            dispatch(registerRequest(req));
        }
    }

    // Hàm thực hiện khi đã hoàn thành form đăng nhập
    const onFinishLogin = async (account: any): Promise<any> => {
        console.log(account);
        const req: LoginRequest = {
            "email": account.EmailLogin,
            "password": account.PasswordLogin,
            "remember": account.remember,
            "additionalProp1": {},

        };
        dispatch(loginRequest(req));
    }

    // Hàm thực hiện trở lại trang chủ
    const onClickBackButton = () => {
        isForgotPasswordVisible ? setIsForgotPasswordVisible(false) : navigate("/");
    }

    // Hàm chuyển đổi giữa đăng nhập và đăng xuất
    const onClickSwitchButton = () => {
        setIsLogin(!isLogin)
        setCheckBeginFrom(true);
        setCheckClickUniversityReview(false);
        setCheckClickLocalReview(false);
    }

    // Hàm thực hiện trở lại các bước đăng nhập
    const onClickBackPage = () => {
        if (checkClickUniversityReview)
            setCurrentUniversity(currentUniversity - 1);
        if (checkClickLocalReview)
            setCurrentLocal(currentLocal - 1);
    }

    // Hàm thực hiện khi click vào nút đánh giá trường đại học
    const handleClickUniversityReview = () => {
        setCheckClickUniversityReview(true);
        setCheckBeginFrom(false);
        setCurrentUniversity(0);
        setIsMailEdu(false);
    }

    // Hàm thực hiện khi click vào nút đánh giá địa phương
    const handleClickLocalReview = () => {
        setCheckClickLocalReview(true);
        setCurrentLocal(0);
        setCheckBeginFrom(false);
        setIsMailEdu(false);
    }

    // Hàm thực hiện khi click vào nút quên mật khẩu
    const onClickForgotPassword = () => {
        setIsForgotPasswordVisible(true);
    }

    // Hàm thực hiện khi click vào nút gửi lại mã xác nhận
    const onClickResendCode = () => {
        setCountdown(30);
        setResendDisabled(false);
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    setResendDisabled(true);
                    clearInterval(intervalId);
                }
                return prevCountdown - 1;
            });
        }, 1000);
        // Show a success message
        message.success("Chúng tôi đã gửi mã xác nhận qua email của bạn!");
    };

    // Hàm thực hiện khi đếm ngược bằng 0
    useEffect(() => {
        countdown === 0 && setResendDisabled(true);
    }, [countdown])

    // Hàm thực hiện khi đã hoàn thành form quên mật khẩu bước 1
    const onFinishForgotPasswordStep1 = (values: any) => {
        setEmailForgotPassword(values.EmailForgotPassword);
        const req = {
            email: values.EmailForgotPassword,
            additionalProp1: {}
        }
        dispatch(forgotPasswordRequest(req));
        setResendDisabled(true);
        message.success("Chúng tôi đã gửi mã xác nhận qua email của bạn!");
    };

    // Hàm thực hiện khi đã hoàn thành form quên mật khẩu bước 2
    const onFinishForgotPasswordStep2 = (values: any) => {
        const req = {
            email: values.EmailChangePassword,
            password: values.NewForgotPassword,
            confirmPassword: values.ConfirmForgotPassword,
            OTPCode: values.CodeForgotPassword,
            additionalProp1: {}
        }
        dispatch(resetPasswordRequest(req));
    };

    useEffect(() => {
        if (changePasswordSuccess) {
            setIsForgotPasswordVisible(false);
            setEmailForgotPassword("");
        }
    }, [changePasswordSuccess])

    const handleChangeUserPositionId = (val: string) => {
        // if (val === positionIdStaffInChangeStartUpInnovate) {
        //     notification.info({
        //         message: 'Lưu ý',
        //         description: 'Vai trò bạn chọn sẽ được sử dụng bảng hỏi đầy đủ',
        //         duration: 5,
        //     });
        // }
        // else {
        //     notification.info({
        //         message: 'Lưu ý',
        //         description: 'Vai trò bạn chọn sẽ được sử dụng bảng hỏi ngắn',
        //         duration: 5,
        //     });
        // }
    }

    return (
        <motion.div className='login-main'
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
            <div className='back-to-login'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <div onClick={onClickBackButton}>
                            <motion.div
                                className='back-button'
                                whileHover={{ scale: 1.5 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            >
                                <div className="icon"><ArrowLeftOutlined /></div>
                                <div className="text">Quay lại</div>
                            </motion.div>
                        </div>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Spin spinning={isLoading} tip="Đang xử lý...">
                <div className='content-main'>
                    <div className='image-of-login'>
                        {!isForgotPasswordVisible ? <div className='title'>Chào mừng bạn đến với V.innovate!</div> : <div className='title forgot-password'>Quên mật khẩu</div>}
                        {!isForgotPasswordVisible &&
                            <div className='sub-title'>Đánh giá, xếp hạng các trường đại học. cao đằng/tỉnh thành phố về đổi mới sáng tạo, khởi nghiệp và tạo tác động.</div>
                        }
                        {(isLogin && !isForgotPasswordVisible) && <motion.img src={LoginImage} alt=''
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                        />}
                        {(!isLogin && !isForgotPasswordVisible) && <motion.img src={LoginImage2} alt=''
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                        />}
                        {isForgotPasswordVisible && <motion.img src={ForgotPassWordImage} alt=''
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 1 }}
                        />}
                    </div>
                    <div className='form-login'>
                        {
                            (isLogin && !isForgotPasswordVisible) &&
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 1 }}
                            >
                                <div className='login-content'>
                                    <div className='title'>VUI LÒNG ĐĂNG NHẬP ĐỂ ĐÁNH GIÁ</div>
                                    {/* <div className='content'>Lorem ipsum dolor sit amet consectetur. Sit mattis mattis non convallis pulvinar sem commodo vitae est.</div> */}
                                </div>
                                <Form
                                    name="basic"
                                    wrapperCol={{ span: 16 }}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinishLogin}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Email/Tài khoản"
                                        name="EmailLogin"
                                        rules={[
                                            { type: 'email', message: 'Email không hợp lệ', },
                                            { required: true, message: 'Vui lòng nhập email!' }
                                        ]}
                                    >
                                        <Input type='email' className='input-login email-input' placeholder='Nhập Email hoặc tài khoản' />
                                    </Form.Item>

                                    <Form.Item
                                        label="Mật khẩu"
                                        name="PasswordLogin"
                                        rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                    >
                                        <Input.Password className='input-login' placeholder='Nhập mật khẩu' />
                                    </Form.Item>

                                    <Form.Item className='remember-forgot-password' name="remember" valuePropName="checked" >
                                        <label className='label-login'>
                                            <Checkbox className='checkbox-login' />
                                            <motion.div
                                                style={{ cursor: 'pointer' }}
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.95 }}
                                                whileFocus={{ scale: 1.1 }}>Nhớ mật khẩu</motion.div>
                                        </label>
                                        <motion.div className='forgot-password'
                                            whileHover={{ scale: 1.3 }}
                                            whileTap={{ scale: 0.95 }}
                                            whileFocus={{ scale: 1.3 }}
                                            onClick={onClickForgotPassword}
                                        >Quên mật khẩu</motion.div>
                                    </Form.Item>
                                    <Form.Item >
                                        <motion.div
                                            whileHover={{ scale: 1.1 }}
                                            whileTap={{ scale: 0.95 }}
                                            whileFocus={{ scale: 1.1 }}
                                        >
                                            <Button className='button-submit' type="text" htmlType="submit">
                                                Đăng nhập
                                            </Button>
                                        </motion.div>
                                    </Form.Item>
                                </Form>
                                <div className='change-to-register'>
                                    Bạn chưa có tài khoản ?
                                    <motion.strong
                                        className='register'
                                        whileHover={{ scale: 1.3 }}
                                        whileTap={{ scale: 0.95 }}
                                        whileFocus={{ scale: 1.3 }}
                                        onClick={onClickSwitchButton}
                                    >
                                        Đăng ký
                                    </motion.strong>
                                </div>
                            </motion.div>
                        }
                        {
                            (!isLogin && !isForgotPasswordVisible) &&
                            <div>
                                {checkBeginFrom &&
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <div className='login-content'>
                                            <div className='title login-1'>BẠN CẦN ĐÁNH GIÁ ĐIỀU GÌ?</div>
                                            <div className='content login-1'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                        </div>
                                        <div className='choose-evaluate'>
                                            <motion.div className='content-box'
                                                whileHover="hover"
                                                whileTap="tap"
                                                variants={hoverVariants}
                                                onClick={handleClickUniversityReview}
                                            >
                                                <div className='title'>ĐÁNH GIÁ TRƯỜNG ĐẠI HỌC</div>
                                                <div className='sub-title'>Quan tâm đánh giá trường đại học, anh/chị có thể tiếp cận đến bộ công cụ U.innovate và U.impact</div>
                                            </motion.div>
                                            <motion.div className='content-box'
                                                whileHover="hover"
                                                whileTap="tap"
                                                variants={hoverVariants}
                                                onClick={handleClickLocalReview}
                                            >
                                                <div className='title'>ĐÁNH GIÁ ĐỊA PHƯƠNG</div>
                                                <div className='sub-title'>Quan tâm đánh giá địa phương, anh/chị có thể tiếp cận đến bộ công cụ P.innovate</div>
                                            </motion.div>
                                        </div>
                                    </motion.div>}

                                {checkClickUniversityReview && <>
                                    {currentUniversity === 0 &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>CUNG CẤP THÔNG TIN CƠ BẢN</div>
                                                <div className='content'>Để xác thực anh, chị thuộc trường đại học được đánh giá, vui lòng cung cấp email tổ chức</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={handleClickFirstStep}
                                                autoComplete="off"
                                                form={form}
                                            >
                                                <Form.Item
                                                    label="Họ và tên"
                                                    name="userName"
                                                    rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}
                                                >

                                                    <Input className='email-input' placeholder='Nhập họ và tên' />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Email"
                                                    name="userEmail"
                                                    rules={[
                                                        { type: 'email', message: 'Email không hợp lệ', },
                                                        { required: true, message: 'Vui lòng nhập email!' }
                                                    ]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập Email' />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Mật khẩu"
                                                    name="userPassword"
                                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                                >
                                                    <Input.Password id='basic_PasswordRegiter' placeholder='Nhập mật khẩu' />
                                                </Form.Item>

                                                <Form.Item
                                                    className='userConfirmPassword'
                                                    label="Xác nhận mật khẩu"
                                                    name="userConfirmPassword"
                                                    dependencies={['userPassword']}
                                                    rules={[
                                                        { required: true, message: 'Vui lòng nhập xác nhận mật khẩu!' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('userPassword') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Mật khẩu xác nhận không đúng!'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password id='basic_ConfirmPasswordRegiter' placeholder='Nhập lại mật khẩu' />
                                                </Form.Item>

                                                <Form.Item >
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}
                                                        whileFocus={{ scale: 1.1 }}
                                                    >
                                                        <Button className='button-submit' type="text" htmlType="submit">
                                                            Tiếp tục
                                                        </Button>
                                                    </motion.div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        direction='horizontal'
                                                        progressDot
                                                        current={currentUniversity}
                                                            items={[{}, {}]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }
                                    {currentUniversity === 1 &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN TẠI CƠ SỞ ĐÀO TẠO</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={handleClickSecondStep}
                                                autoComplete="off"
                                                form={form}
                                            >
                                                <Form.Item
                                                    label="Cơ sở đào tạo"
                                                    name="typeOfFacility"
                                                    rules={[{ required: true, message: 'Vui lòng cơ sở đào tạo!' }]}>
                                                    <Select
                                                        suffixIcon={<CaretDownOutlined />}
                                                        placeholder="Chọn cơ sở đào tạo"
                                                        onChange={handleOnChangeTypeOfFacility}
                                                        onDropdownVisibleChange={handleTypeOfFacilityVisibleChange}
                                                    >
                                                        <Option value='1'>Đại học</Option>
                                                        <Option value='2'>Cao đẳng</Option>
                                                    </Select>
                                                </Form.Item>
                                                {checkFacility === 1 &&
                                                    <motion.div
                                                        initial={{ opacity: 0, marginTop: 70 }}
                                                        animate={checkClickTypeOfFacility ? { opacity: 1, marginTop: 70 } : { opacity: 1, marginTop: 0 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.25 }}
                                                    >
                                                        <Form.Item
                                                            label="Chọn trường đại học"
                                                            name="userFacilityUniversitiesId"
                                                            rules={[{ required: true, message: 'Vui lòng chọn trường đại học!' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                suffixIcon={<CaretDownOutlined />}
                                                                placeholder="Tìm kiếm trường đại học"
                                                                onDropdownVisibleChange={handleFacilityVisibleChange}
                                                            >
                                                                {lstFacilityByDescription?.universities.map((index) => (
                                                                    <Option value={index.id}>{index.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </motion.div>
                                                }
                                                {checkFacility === 2 &&
                                                    <motion.div
                                                        initial={{ opacity: 0, marginTop: 70 }}
                                                        animate={checkClickTypeOfFacility ? { opacity: 1, marginTop: 70 } : { opacity: 1, marginTop: 0 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.25 }}
                                                    >
                                                        <Form.Item
                                                            label="Chọn trường cao đẳng"
                                                            name="userFacilityCollegesId"
                                                            rules={[{ required: true, message: 'Vui lòng chọn trường cao đẳng!' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                suffixIcon={<CaretDownOutlined />}
                                                                placeholder="Tìm kiếm theo cao đẳng"
                                                                onDropdownVisibleChange={handleFacilityVisibleChange}
                                                            >
                                                                {lstFacilityByDescription?.colleges.map((index) => (
                                                                    <Option value={index.id}>{index.name}</Option>
                                                                ))}
                                                            </Select>
                                                        </Form.Item>
                                                    </motion.div>
                                                }
                                                <motion.div
                                                    initial={{ marginTop: 0 }}
                                                    animate={checkClickFacility ? { marginTop: 260 } : { marginTop: 0 }}
                                                    exit={{ marginTop: 0 }}
                                                    transition={{ duration: 0.25 }}>
                                                    <Form.Item
                                                        label="Vai trò tại cơ sở đào tạo"
                                                        name="userPositionId"
                                                        rules={[{ required: true, message: 'Vui lòng chọn vai trò cơ sở đào tạo!' }]}
                                                    >
                                                        <Select
                                                            suffixIcon={<CaretDownOutlined />}
                                                            placeholder="Chọn vai trò"
                                                            onDropdownVisibleChange={handlePositionVisibleChange}
                                                                onChange={(val) => handleChangeUserPositionId(val)}
                                                        >
                                                            {lstPositonUniversity.map((index) => (
                                                                <>
                                                                    {index.id === positionIdStudent && <Option value={index.id}>{index.name}</Option>}
                                                                    {index.id === positionIdOldStudent && <Option value={index.id}>{index.name}</Option>}
                                                                    {(index.id !== positionIdStudent && index.id !== positionIdOldStudent && isMailEdu) && <Option value={index.id}>{index.name}</Option>}
                                                                    {(index.id !== positionIdStudent && index.id !== positionIdOldStudent && !isMailEdu) && <Option value={index.id} disabled>{index.name}</Option>}
                                                                </>
                                                                // <Option value={index.id}>{index.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </motion.div>

                                                <Form.Item >
                                                    <motion.div
                                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                                        initial={{ marginTop: 10 }}
                                                        animate={checkClickPosition ? { marginTop: 130 } : { marginTop: 10 }}
                                                    >
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                                <Button loading={loadingRegister} className='button-submit' type="text" htmlType="submit" style={{ marginRight: 10 }}>
                                                                    Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </motion.div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentUniversity}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>}
                                    {(currentUniversity === 2 && userPositionId !== positionIdStaffInChange) &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN CHUYÊN SÂU</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={onFinishRegister}
                                                autoComplete="off"
                                                form={form}

                                            >
                                                <Form.Item
                                                    label="Anh/chị thuộc Khoa/Viện nào tại cơ sở đào tạo?"
                                                    name="FinalInfo-1"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleBelongToChange(e)} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Vai trò của anh/chị tại Cơ sở đào tạo? "
                                                    name="FinalInfo-2"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleRoleInFacilityChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Lĩnh vực anh, chị phụ trách?"
                                                    name="FinalInfo-3"
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleResponsibleFieldChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-1"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận Tuyên bố về quyền riêng tư
                                                            Bằng cách chọn hộp này, tôi xác nhận rằng tôi muốn đăng ký dịch vụ này và tôi đồng ý cho IID xử lý dữ liệu cá nhân của tôi cho mục đích được mô tả trong tuyên bố về quyền riêng tư (nghĩa là để nhận thông tin được yêu cầu về các chủ đề khác nhau trong lĩnh vực dịch vụ của V.innovate thông qua bản tin hoặc thông báo của chúng tôi)   </>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-2"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận <strong>  Điều khoản và Điều kiện</strong></>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-3"
                                                    className='agreement'
                                                    valuePropName="checked"

                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thông tin cập nhật về V.innovate</>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-4"
                                                    className='agreement'
                                                    valuePropName="checked"
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thêm thông tin về cách sử dụng trang web V.innovate</>
                                                    </label>
                                                </Form.Item>

                                                <Form.Item >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-submit' type="text" htmlType="submit" style={{ marginRight: 10 }}>
                                                                Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentUniversity}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }
                                    {(currentUniversity === 2 && userPositionId === positionIdStaffInChange) &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN CHUYÊN SÂU</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={onFinishRegister}
                                                autoComplete="off"
                                                form={form}

                                            >
                                                <Form.Item
                                                    label="Anh/chị thuộc Tổ chức nào ?"
                                                    name="input-1"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleBelongToChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Vai trò của anh/chị tại tổ chức của mình?"
                                                    name="input-2"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleRoleInFacilityChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Lĩnh vực anh, chị phụ trách?"
                                                    name="input-3"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleResponsibleFieldChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-1"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận Tuyên bố về quyền riêng tư
                                                            Bằng cách chọn hộp này, tôi xác nhận rằng tôi muốn đăng ký dịch vụ này và tôi đồng ý cho IID xử lý dữ liệu cá nhân của tôi cho mục đích được mô tả trong tuyên bố về quyền riêng tư (nghĩa là để nhận thông tin được yêu cầu về các chủ đề khác nhau trong lĩnh vực dịch vụ của V.innovate thông qua bản tin hoặc thông báo của chúng tôi)   </>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-2"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận <strong>  Điều khoản và Điều kiện</strong></>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-3"
                                                    className='agreement'
                                                    valuePropName="checked"

                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thông tin cập nhật về V.innovate</>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-4"
                                                    className='agreement'
                                                    valuePropName="checked"
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thêm thông tin về cách sử dụng trang web V.innovate</>
                                                    </label>
                                                </Form.Item>

                                                <Form.Item >
                                                    <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}>
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-submit' type="primary" htmlType="submit" style={{ marginRight: 10 }}>
                                                                Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentUniversity}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }</>
                                }
                                {checkClickLocalReview && <>
                                    {currentLocal === 0 &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>CUNG CẤP THÔNG TIN CƠ BẢN</div>
                                                <div className='content'>Để chọn vai trò là cán bộ chuyên trách tại  địa phương, anh/chị cần cung cấp email có đuôi gov.vn?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={handleClickFirstStep}
                                                autoComplete="off"
                                                form={form}
                                            >
                                                <Form.Item
                                                    label="Họ và tên"
                                                    name="userName"
                                                    rules={[{ required: true, message: 'Vui lòng nhập họ tên!' }]}
                                                >

                                                    <Input className='email-input' placeholder='Nhập họ và tên' />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Email"
                                                    name="userEmail"
                                                    rules={[
                                                        { type: 'email', message: 'Email không hợp lệ', },
                                                        { required: true, message: 'Vui lòng nhập email!' }
                                                    ]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập Email' />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Mật khẩu"
                                                    name="userPassword"
                                                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
                                                >
                                                    <Input.Password id='basic_PasswordRegiter' placeholder='Nhập mật khẩu' />
                                                </Form.Item>

                                                <Form.Item
                                                    className='userConfirmPassword'
                                                    label="Xác nhận mật khẩu"
                                                    name="userConfirmPassword"
                                                    dependencies={['userPassword']}
                                                    rules={[
                                                        { required: true, message: 'Vui lòng nhập xác nhận mật khẩu!' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('userPassword') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Mật khẩu xác nhận không đúng!'));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password id='basic_ConfirmPasswordRegiter' placeholder='Nhập lại mật khẩu' />
                                                </Form.Item>

                                                <Form.Item >
                                                    <motion.div
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}
                                                        whileFocus={{ scale: 1.1 }}
                                                    >
                                                        <Button className='button-submit' type="text" htmlType="submit">
                                                            Tiếp tục
                                                        </Button>
                                                    </motion.div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        direction='horizontal'
                                                        progressDot
                                                        current={currentLocal}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }
                                    {currentLocal === 1 &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN TẠI ĐỊA PHƯƠNG</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={handleClickSecondStep}
                                                autoComplete="off"
                                                form={form}
                                            >
                                                <motion.div
                                                    initial={{ opacity: 0, marginTop: 70 }}
                                                    animate={checkClickTypeOfFacility ? { opacity: 1, marginTop: 70 } : { opacity: 1, marginTop: 0 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ duration: 0.25 }}
                                                >
                                                    <Form.Item
                                                        label="Anh chị đến từ tỉnh, thành phố nào ?"
                                                        name="userLocalId"
                                                        rules={[{ required: true, message: 'Vui lòng chọn tỉnh / thành phố!' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                            suffixIcon={<CaretDownOutlined />}
                                                            placeholder="Tìm kiếm tỉnh / thành phố"
                                                            onDropdownVisibleChange={handleFacilityVisibleChange}
                                                        >
                                                            {lstAddresses.map((index) => (
                                                                <Option value={index.id}>{index.name}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </motion.div>
                                                <motion.div
                                                    initial={{ marginTop: 0 }}
                                                    animate={checkClickFacility ? { marginTop: 260 } : { marginTop: 0 }}
                                                    exit={{ marginTop: 0 }}
                                                    transition={{ duration: 0.25 }}>
                                                    <Form.Item
                                                        label="Vai trò tại hệ sinh thái của địa phương ?"
                                                        name="userPositionLocalId"
                                                        rules={[{ required: true, message: 'Vui lòng chọn vai trò cơ sở đào tạo!' }]}
                                                    >
                                                        <Select
                                                            suffixIcon={<CaretDownOutlined />}
                                                            placeholder="Chọn vai trò"
                                                            onDropdownVisibleChange={handlePositionVisibleChange}
                                                        >
                                                            {lstPositonLocal.map((index) => (
                                                                <>
                                                                    {(index.id !== positionIdStaffInChangeStartUp) && <Option value={index.id}>{index.name}</Option>}
                                                                    {(index.id === positionIdStaffInChangeStartUp && isMailEdu) && <Option value={index.id}>{index.name}</Option>}
                                                                    {(index.id === positionIdStaffInChangeStartUp && !isMailEdu) && <Option value={index.id} disabled>{index.name}</Option>}
                                                                </>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </motion.div>

                                                <Form.Item >
                                                    <motion.div
                                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                                        initial={{ marginTop: 10 }}
                                                        animate={checkClickPosition ? { marginTop: 260 } : { marginTop: 10 }}
                                                    >
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                                <Button loading={loadingRegister} className='button-submit' type="text" htmlType="submit" style={{ marginRight: 10 }}>
                                                                    Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </motion.div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentLocal}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>}
                                    {(currentLocal === 2 && userPositionLocalId === positionIdStaffInChangeStartUp) &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN CHUYÊN SÂU</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={onFinishRegister}
                                                autoComplete="off"
                                                form={form}

                                            >
                                                <Form.Item
                                                    label="Anh/chị thuộc Sở, ban ngành nào?"
                                                    name="FinalInfo-1"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleBelongToChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Vai trò của anh/chị tại cơ quan của mình? "
                                                    name="FinalInfo-2"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleRoleInFacilityChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Lĩnh vực anh, chị phụ trách?"
                                                    name="FinalInfo-3"
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleResponsibleFieldChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-1"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận Tuyên bố về quyền riêng tư
                                                            Bằng cách chọn hộp này, tôi xác nhận rằng tôi muốn đăng ký dịch vụ này và tôi đồng ý cho IID xử lý dữ liệu cá nhân của tôi cho mục đích được mô tả trong tuyên bố về quyền riêng tư (nghĩa là để nhận thông tin được yêu cầu về các chủ đề khác nhau trong lĩnh vực dịch vụ của V.innovate thông qua bản tin hoặc thông báo của chúng tôi)   </>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-2"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận <strong>  Điều khoản và Điều kiện</strong></>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-3"
                                                    className='agreement'
                                                    valuePropName="checked"

                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thông tin cập nhật về V.innovate</>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-4"
                                                    className='agreement'
                                                    valuePropName="checked"
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thêm thông tin về cách sử dụng trang web V.innovate</>
                                                    </label>
                                                </Form.Item>

                                                <Form.Item >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-submit' type="text" htmlType="submit" style={{ marginRight: 10 }}>
                                                                Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentLocal}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }
                                    {(currentLocal === 2 && userPositionLocalId !== positionIdStaffInChangeStartUp) &&
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 1 }}>
                                            <div className='login-content'>
                                                <div className='title'>THÔNG TIN CHUYÊN SÂU</div>
                                                <div className='content'>Chúng tôi muốn xác định bạn có đến từ tổ chức được đánh giá hay không?</div>
                                            </div>
                                            <Form
                                                name="basic"
                                                wrapperCol={{ span: 16 }}
                                                initialValues={{ remember: true }}
                                                onFinish={onFinishRegister}
                                                autoComplete="off"
                                                form={form}

                                            >
                                                <Form.Item
                                                    label="Anh/chị thuộc Tổ chức nào ?"
                                                    name="input-1"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleBelongToChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Vai trò của anh/chị tại tổ chức của mình?"
                                                    name="input-2"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleRoleInFacilityChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Lĩnh vực anh, chị phụ trách?"
                                                    name="input-3"
                                                    rules={[{ required: true, message: 'Vui lòng nhập thông tin!' }]}
                                                >
                                                    <Input className='email-input' placeholder='Nhập câu trả lời'
                                                        onChange={(e) => handleResponsibleFieldChange(e)}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-1"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận Tuyên bố về quyền riêng tư
                                                            Bằng cách chọn hộp này, tôi xác nhận rằng tôi muốn đăng ký dịch vụ này và tôi đồng ý cho IID xử lý dữ liệu cá nhân của tôi cho mục đích được mô tả trong tuyên bố về quyền riêng tư (nghĩa là để nhận thông tin được yêu cầu về các chủ đề khác nhau trong lĩnh vực dịch vụ của V.innovate thông qua bản tin hoặc thông báo của chúng tôi)   </>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    className='agreement'
                                                    name="agreement-2"
                                                    valuePropName="checked"
                                                    rules={[
                                                        {
                                                            validator: (_, value) =>
                                                                value ? Promise.resolve() : Promise.reject(new Error('Vui lòng chấp nhận điều khoản')),
                                                        },
                                                    ]}
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi chấp nhận <strong>  Điều khoản và Điều kiện</strong></>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-3"
                                                    className='agreement'
                                                    valuePropName="checked"

                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thông tin cập nhật về V.innovate</>
                                                    </label>
                                                </Form.Item>
                                                <Form.Item
                                                    name="agreement-4"
                                                    className='agreement'
                                                    valuePropName="checked"
                                                >
                                                    <label className='label-login label-agreement'>
                                                        <Checkbox className='checkbox-login' />
                                                        <>Tôi muốn nhận thêm thông tin về cách sử dụng trang web V.innovate</>
                                                    </label>
                                                </Form.Item>

                                                <Form.Item >
                                                    <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}>
                                                        <motion.div
                                                            style={{ width: '100%', marginRight: '20px' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-back' onClick={onClickBackPage}>
                                                                Quay lại
                                                            </Button>
                                                        </motion.div>
                                                        <motion.div
                                                            style={{ width: '100%' }}
                                                            whileHover={{ scale: 1.1 }}
                                                            whileTap={{ scale: 0.95 }}>
                                                            <Button className='button-submit' type="primary" htmlType="submit" style={{ marginRight: 10 }}>
                                                                Đăng ký
                                                            </Button>
                                                        </motion.div>
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className='step-item'>
                                                    <Steps
                                                        progressDot
                                                        current={currentLocal}
                                                            items={[{}, {},]}
                                                    />
                                                </Form.Item>
                                            </Form>
                                        </motion.div>
                                    }</>
                                }
                                <div className='change-to-register'>
                                    Bạn đã có tài khoản ?
                                    <motion.strong
                                        className='register'
                                        whileHover={{ scale: 1.3 }}
                                        whileTap={{ scale: 0.95 }}
                                        whileFocus={{ scale: 1.3 }}
                                        onClick={onClickSwitchButton}
                                    >
                                        Đăng nhập
                                    </motion.strong>
                                </div>
                            </div>
                        }
                        {
                            (isForgotPasswordVisible) &&
                            <div>
                                {!EmailForgotPassword &&
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <div className='login-content'>
                                            <div className='title' style={{ whiteSpace: 'nowrap' }}>THAY ĐỔI MẬT KHẨU CỦA BẠN</div>
                                            <div className="content">Để thiết lập lại mật khẩu, hãy nhập email hoặc tài khoản đã đăng ký để lấy lại mật khẩu tài khoản</div>
                                        </div>
                                        <Form
                                            name="basic"
                                            wrapperCol={{ span: 16 }}
                                            initialValues={{ remember: true }}
                                            onFinish={onFinishForgotPasswordStep1}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Email/Tài khoản"
                                                name="EmailForgotPassword"
                                                rules={[
                                                    { type: 'email', message: 'Email không hợp lệ', },
                                                    { required: true, message: 'Vui lòng nhập email!' }
                                                ]}
                                            >
                                                <Input className='input-login email-input' placeholder='Nhập Email hoặc tài khoản' />
                                            </Form.Item>

                                            <Form.Item >
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    whileFocus={{ scale: 1.1 }}
                                                >
                                                    <Button className='button-submit' type="text" htmlType="submit">
                                                        Tiếp tục
                                                    </Button>
                                                </motion.div>
                                            </Form.Item>
                                        </Form>
                                    </motion.div>
                                }
                                {(EmailForgotPassword && !CodeForgotPassword) &&
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 1 }}
                                    >
                                        <div className='login-content'>
                                            <div className='title' style={{ whiteSpace: 'nowrap' }}>THAY ĐỔI MẬT KHẨU CỦA BẠN</div>
                                            <div className="content">Để thiết lập lại mật khẩu, hãy nhập email hoặc tài khoản đã đăng ký để lấy lại mật khẩu tài khoản</div>
                                        </div>
                                        <Form
                                            name="basic"
                                            wrapperCol={{ span: 16 }}
                                            initialValues={{ remember: true }}
                                            onFinish={onFinishForgotPasswordStep2}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Email"
                                                name="EmailChangePassword"
                                                rules={[{ required: true, message: 'Vui lòng nhập email!' }]}
                                            >
                                                <Input className='input-login email-input' placeholder='Nhận email' />
                                            </Form.Item>
                                            <Form.Item
                                                label="Mật khẩu mới"
                                                name="NewForgotPassword"
                                                rules={[{ required: true, message: 'Vui lòng mật khẩu mới!' }]}
                                            >
                                                <Input.Password className='input-login ' placeholder='Nhận mật khẩu mới' />
                                            </Form.Item>

                                            <Form.Item
                                                className='userConfirmPassword'
                                                label="Xác nhận mật khẩu"
                                                name="ConfirmForgotPassword"
                                                dependencies={['NewForgotPassword']}
                                                rules={[
                                                    { required: true, message: 'Vui lòng nhập xác nhận mật khẩu!' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('NewForgotPassword') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Mật khẩu xác nhận không đúng!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password id='basic_ConfirmPasswordRegiter' placeholder='Nhập lại mật khẩu' />
                                            </Form.Item>
                                            <Form.Item
                                                label="Mã xác nhận"
                                                name="CodeForgotPassword"
                                                rules={[{ required: true, message: 'Vui lòng mã xác nhận!' }]}
                                            >
                                                <Input.Password className='input-login' placeholder='Nhận mã xác nhận' />
                                            </Form.Item>
                                            {/* {!resendDisabled && (

                                            <div className='send-code'>
                                                Mã xác thực đang được gửi vào số điện thoại bạn đã đăng ký tài khoản. Gửi lại mã sau{" "}
                                                <strong style={{ fontWeight: "bold" }}>
                                                    {Math.floor(countdown / 60).toString().padStart(2, "0")}:
                                                    {Math.floor(countdown % 60).toString().padStart(2, "0")}
                                                </strong>{" "}
                                            </div>
                                        )}

                                        {resendDisabled && (
                                            <div className='send-code'>
                                                Mã xác thực đang được gửi vào số điện thoại bạn đã đăng ký tài khoản.{" "}
                                                <strong
                                                    onClick={onClickResendCode}>
                                                    Gửi lại mã
                                                </strong>{" "}
                                            </div>

                                        )} */}
                                            <Form.Item >
                                                <motion.div
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.95 }}
                                                    whileFocus={{ scale: 1.1 }}
                                                >
                                                    <Button className='button-submit' type="text" htmlType="submit">
                                                        Xác nhận
                                                    </Button>
                                                </motion.div>
                                            </Form.Item>
                                        </Form>
                                    </motion.div>
                                }
                                {/* {CodeForgotPassword &&
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 1 }}
                                >
                                    <div className='login-content'>
                                        <div className='title' style={{ whiteSpace: 'nowrap' }}>THAY ĐỔI MẬT KHẨU CỦA BẠN</div>
                                        <div className="content">Để thiết lập lại mật khẩu, hãy nhập email hoặc tài khoản đã đăng ký để lấy lại mật khẩu tài khoản</div>
                                    </div>
                                    <Form
                                        name="basic"
                                        wrapperCol={{ span: 16 }}
                                        initialValues={{ remember: true }}
                                        onFinish={onFinishForgotPasswordStep2}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Mật khẩu cũ"
                                            name="OldForgotPassword"
                                            rules={[{ required: true, message: 'Vui lòng mật khẩu cũ!' }]}
                                        >
                                            <Input.Password className='input-login' placeholder='Nhận mật khẩu cũ' />
                                        </Form.Item>
                                        <Form.Item
                                            label="Mật khẩu mới"
                                            name="NewForgotPassword"
                                            rules={[{ required: true, message: 'Vui lòng mật khẩu mới!' }]}
                                        >
                                            <Input.Password className='input-login' placeholder='Nhận mật khẩu mới' />
                                        </Form.Item>
                                        <Form.Item
                                            label="Nhập lại mật khẩu mới"
                                            name="ConfirmForgotPassword"
                                            rules={[{ required: true, message: 'Vui lòng nhập lại mật khẩu mới!' }]}
                                        >
                                            <Input.Password className='input-login' placeholder='Nhập lại mật khẩu mới' />
                                        </Form.Item>
                                        <Form.Item >
                                            <motion.div
                                                whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.95 }}
                                                whileFocus={{ scale: 1.1 }}
                                            >
                                                <Button className='button-submit' type="text" htmlType="submit">
                                                    Xác nhận
                                                </Button>
                                            </motion.div>
                                        </Form.Item>
                                    </Form>
                                </motion.div>
                            } */}
                            </div>
                        }
                        {

                        }
                    </div>
                </div>
            </Spin>
            {registerSuccess && <ActiveAccountModel email={userEmail} />}
        </motion.div>
    )
}

export default Login