import { motion } from 'framer-motion'
import React from 'react'
import './privacy_prolicy.scss'
const PrivacyPolicy = () => {
    return (
        <motion.div className='privacy-policy-main'
            initial={{ width: 0 }}
            animate={{ width: 850 }}
            exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
            <div className="header">
                <div className="title">Chính sách bảo mật</div>
                <div className="content">Cập nhật lần cuối: 01/03/2023</div>
            </div>
            <div className="body">
                <div className="title">
                    Tổng quan
                </div>
                <div className="content">
                    <p>
                        Cảm ơn bạn đã truy cập Trang web Vinnovate , một sáng kiến ​​của Viện IID. Chúng tôi tạo chính sách bảo mật này để cung cấp cho bạn thông tin về cách chúng tôi thu thập, sử dụng, chia sẻ và bảo mật thông tin cá nhân của bạn. Chính sách quyền riêng tư này được đưa vào và là một phần của Điều khoản và điều kiện của Vinnovate, điều chỉnh việc bạn sử dụng Trang web nói chung.
                    </p>
                    <p>
                        Tuyên bố về quyền riêng tư này chỉ bao gồm Vinnovate. Nó không bao gồm các trang web liên kết đến hoặc từ trang web này.
                    </p>
                </div>
                <div className="title">
                    Hướng dẫn Nội dung:
                </div>
                <div className="sub-title">1. Mục đích của việc thu thập dữ liệu là gì?</div>
                <div className="content">Mục đích của việc thu thập dữ liệu là để nắm bắt sự phát triển của thể chế theo thời gian (nội bộ, nhưng không bao giờ là liên thể chế).</div>
                <div className="sub-title">2. Chúng tôi thu thập thông tin gì?</div>
                <div className="content">Thông tin bạn cung cấp cho chúng tôi. Khi bạn sử dụng Trang web của chúng tôi, kể cả khi bạn tạo tài khoản người dùng, bắt đầu hoặc gửi bản tự đánh giá hoặc khi bạn đặt câu hỏi thông qua Trang web, bạn có thể cung cấp cho chúng tôi thông tin nhận dạng của bạn hoặc các liên hệ của bạn (thông tin cá nhân), ví dụ như:</div>
                <ul>
                    <li>Tên đầy đủ, địa chỉ, địa chỉ e-mail của bạn, thông tin khác có thể xác định bạn là một cá nhân, ví dụ: tỉnh thành, tên của cơ sở giáo dục đại học (hoặc tổ chức), vai trò của bạn, tên khoa/bộ môn và lĩnh vực.</li>
                    <li>Mọi thông tin cá nhân bạn gửi cho chúng tôi trong bất kỳ bảng tin nào.</li>
                </ul>
                <div className="content">Chúng tôi sẽ tự động thu thập thông tin từ bạn. Khi bạn sử dụng Trang web, chúng tôi cũng có thể thu thập thông tin về việc bạn sử dụng Trang web và thiết bị được sử dụng để truy cập Trang web có liên quan đến bạn, nhưng bản thân thông tin được thu thập này không nhận dạng bạn (thông tin phi cá nhân). Thông tin đó bao gồm:</div>
                <ul>
                    <li>Địa chỉ IP và vị trí quốc gia.</li>
                    <li>Thông tin trình duyệt (bao gồm loại trình duyệt, khả năng và ngôn ngữ).</li>
                    <li>Hệ điều hành.</li>
                    <li>Ngày và giờ bạn truy cập Trang web.</li>
                    <li>Thông tin sử dụng trang web, bao gồm các đường dẫn được thực hiện khi bạn di chuyển từ trang web này sang trang web khác (nghĩa là hoạt động "luồng nhấp chuột" của bạn).</li>
                </ul>
                <div className="content">Trong phạm vi Vinnovate liên kết thông tin cá nhân của bạn với thông tin phi cá nhân trong cơ sở dữ liệu của chúng tôi, chúng tôi sẽ coi tất cả thông tin liên quan là thông tin cá nhân theo Chính sách quyền riêng tư này.</div>
                <div className="sub-title">3. Ai có quyền truy cập vào thông tin của bạn?</div>
                <div className="content">
                    <p>Kết quả tự đánh giá chỉ có sẵn cho người dùng đã đăng ký hoặc quản trị viên nhóm. Hồ sơ đã đăng ký cấp quyền truy cập vào tất cả các bản tự đánh giá do người dùng hoàn thành, có thể được sử dụng cho mục đích so sánh nội bộ của riêng họ.</p>
                    <p>Theo nguyên tắc chung, nhóm quản lý của bộ xử lý và nhóm quản trị viên và nhà phát triển trang web có quyền truy cập vào dữ liệu nhờ quản trị trang web. Họ bị ràng buộc bởi Luật bảo vệ dữ liệu hiện hành, vì vậy đảm bảo rằng không có thông tin nào được thu thập sẽ được truyền đạt cho bất kỳ ai bên ngoài.</p>
                    <p>Các nhà cung cấp dịch vụ đóng vai trò là bộ xử lý và theo hợp đồng ràng buộc phải tôn trọng tính bảo mật của bất kỳ thông tin nào được họ chú ý trong quá trình thực hiện công việc và họ sẽ không tiết lộ cho bên thứ ba hoặc sử dụng vì lợi ích của chính họ hoặc của bất kỳ bên thứ ba nào , bất kỳ tài liệu hoặc thông tin nào không có sẵn công khai.</p>
                </div>
                <div className="sub-title">4. Chúng tôi sử dụng thông tin của bạn cho mục đích gì?</div>
                <div className="content">Vinnovate (và các nhà cung cấp dịch vụ của chúng tôi) có thể sử dụng thông tin cá nhân của bạn:</div>
                <ul>
                    <li>Để cung cấp các tính năng và chức năng của Trang web cho bạn.</li>
                    <li>Để xác định bạn là người gửi và xử lý e-mail của bạn.</li>
                    <li>Để trả lời các câu hỏi và ý kiến.</li>
                    <li>Để gửi cho bạn những thông điệp phù hợp giúp bạn sử dụng Trang web của chúng tôi.</li>
                    <li>Để cải thiện, nâng cao và điều chỉnh Trang web cho phù hợp với bạn, đồng thời cải thiện và nâng cao trải nghiệm Trang web tổng thể cho tất cả người dùng Trang web.</li>
                    <li>Để thực hiện các hành động cụ thể mà bạn có thể yêu cầu.</li>
                    <li>Cho mục đích thống kê theo cách tổng hợp và ẩn danh.</li>
                </ul>
                <div className="sub-title">5. Chúng tôi sử dụng phương tiện kỹ thuật nào để xử lý dữ liệu của bạn?</div>
                <div className="content">Thông tin được lưu trữ trên một máy chủ bảo mật được lưu trữ trong một trung tâm dữ liệu được bảo vệ.</div>
                <div className="sub-title">6. Chúng tôi giữ dữ liệu của bạn trong bao lâu?</div>
                <div className="content">Chúng tôi lưu trữ dữ liệu của bạn trong bảy năm kể từ lần đăng ký đầu tiên. Khoảng thời gian tối đa này đã được quyết định sau khi tham khảo ý kiến ​​không chính thức với các chuyên gia của Vinnovate trên cơ sở tỷ lệ lợi ích chi phí tối ưu. Giới hạn lưu giữ này mang lại lợi ích cho người dùng để đánh giá tiến trình của họ trong khi dữ liệu của họ được lưu trữ trong một khoảng thời gian hợp lý.</div>
                <div className="sub-title">7. Bạn có thể truy cập dữ liệu cá nhân của mình như thế nào, xác minh tính chính xác của dữ liệu đó, nếu cần có thể sửa hoặc yêu cầu xóa dữ liệu đó?</div>
                <div className="content">
                    <p>Bạn có thể truy cập và sửa đổi một số thông tin cá nhân được lưu trữ trên Trang web, nếu bạn đã gửi thông tin cá nhân khi tạo tài khoản người dùng hoặc hoàn thành tự đánh giá, chẳng hạn như tên, địa chỉ email, tỉnh thành, tổ chức, vai trò, lĩnh vực, khoa/bộ môn và thành viên nhóm bằng cách đăng nhập vào tài khoản của bạn tại Trang web. Người dùng Trang web có trách nhiệm đảm bảo rằng thông tin được cung cấp là chính xác.</p>
                    <p>Bạn cũng có thể xóa tài khoản của mình. Sau khi xóa tài khoản của bạn, Vinnovate sẽ xóa tài khoản của bạn khỏi máy chủ Trang web của chúng tôi.</p>
                    <p>Trong trường hợp có vấn đề với quyền truy cập cá nhân vào hệ thống, người dùng có thể liên hệ với bộ xử lý quản lý trang web.</p>
                </div>
                <div className="sub-title">8. Chúng tôi chia sẻ thông tin của bạn như thế nào?</div>
                <div className="content">
                    Vinnovate có thể chia sẻ thông tin cá nhân của bạn với các nhà cung cấp dịch vụ bên thứ ba khi thông tin cần thiết để họ thực hiện nhiệm vụ của mình. Các nhà cung cấp dịch vụ bên thứ ba này bị hạn chế theo hợp đồng trong việc sử dụng thông tin của bạn cho bất kỳ mục đích nào khác và được yêu cầu tuân thủ tất cả các luật hiện hành đối với thông tin họ nhận được.
                </div>
                <div className="sub-title">9. Cookie và các công nghệ khác, trang web, nội dung và tài nguyên của bên thứ ba Cookie:</div>
                <div className="content">
                    <p>Vinnovate sử dụng công nghệ cookie theo luật hiện hành như một phần của báo cáo thống kê của Trang web và cho các mục đích khác liên quan đến việc cung cấp Trang web cho bạn. Cookie là một mẩu thông tin nhỏ được gửi bởi một trang web được trình duyệt trên máy tính của bạn lưu trên đĩa cứng của bạn. Nó chứa thông tin mà một trang web có thể cần để thu thập dữ liệu thống kê và do đó cá nhân hóa trải nghiệm của bạn.</p>
                    <p>Nếu muốn, bạn có thể định cấu hình trình duyệt của mình để từ chối tất cả cookie hoặc thông báo cho bạn khi cookie được đặt. Mỗi trình duyệt đều khác nhau, vì vậy hãy kiểm tra menu "Trợ giúp" trên trình duyệt của bạn để tìm hiểu cách thay đổi tùy chọn cookie và quyền riêng tư của bạn. Để biết thêm thông tin về cookie HTTP và cách tắt chúng hoặc hạn chế các danh mục cookie bạn muốn chấp nhận, bạn có thể tham khảo thông tin tại www.allaboutcookies.org/manage-cookies/. Tuy nhiên, nếu bạn từ chối cookie, việc sử dụng Trang web của bạn có thể bị ảnh hưởng.</p>
                    <p>Trang web của bên thứ ba: Lưu ý rằng khi bạn đang sử dụng Trang web, bạn có thể được chuyển hướng qua các liên kết hoặc các phương tiện khác đến các trang web, nội dung và tài nguyên của bên thứ ba không được liên kết với Trang web. Các trang web, nội dung và tài nguyên của bên thứ ba này có thể gửi cookie của riêng họ cho khách truy cập, thu thập dữ liệu hoặc thu hút và sử dụng thông tin cá nhân của bạn theo những cách khác với quy định trong Chính sách quyền riêng tư này. Chính sách quyền riêng tư của các trang web, nội dung và tài nguyên của bên thứ ba này có thể rất khác so với Chính sách quyền riêng tư này. Chúng tôi không chịu trách nhiệm về các biện pháp bảo mật của các nhà cung cấp các trang web, nội dung và tài nguyên khác này và không thể đảm bảo tính bảo mật của bất kỳ thông tin cá nhân nào của bạn được thu thập, sử dụng hoặc chia sẻ ở đó.</p>
                </div>
                <div className="sub-title">10. Bảo mật thông tin cá nhân của bạn</div>
                <div className="content">Vinnovate thực hiện tất cả các bước hợp lý để bảo vệ tính bảo mật và tính toàn vẹn của tất cả thông tin cá nhân được cung cấp cho Trang web này. Trang web được bảo mật bằng cách sử dụng mã hóa SSL tiêu chuẩn của ngành để đảm bảo rằng dữ liệu cá nhân không bao giờ được gửi qua Internet mà không được mã hóa và do đó người xem bên thứ ba không thể đọc được.</div>
                <div className="sub-title">11. Cập nhật chính sách bảo mật</div>
                <div className="content">Đôi khi, chúng tôi có thể sửa đổi Chính sách quyền riêng tư này để phản ánh các sáng kiến ​​của ngành, những thay đổi về luật hoặc công nghệ hoặc những thay đổi trong thực tiễn quyền riêng tư của chúng tôi. Nếu chúng tôi thực hiện các sửa đổi quan trọng đối với Chính sách quyền riêng tư này, chúng tôi sẽ thông báo cho bạn về những thay đổi đó bằng cách: (1) đặt thông báo trên Trang web hoặc (2) thực hiện các hành động khác, nếu phù hợp trong các trường hợp (bao gồm đăng phiên bản sửa đổi của Chính sách quyền riêng tư trực tuyến này).</div>
            </div>
        </motion.div >
    )
}

export default PrivacyPolicy
