import React, { useEffect, useState } from 'react'
import './style.usermanagement.scss'
import { Button, Input, Modal, Select, Spin, Table, Tooltip, notification } from 'antd'
import { IUserAdmin } from '../../../common/u-innovate/define-admin'
import { ColumnType } from 'antd/lib/table'
import axios from 'axios'
import { GrView } from 'react-icons/gr'
import { saveAs } from 'file-saver';


const UserManagement = () => {
    const columns: ColumnType<any>[] = [
        {
            title: 'STT',
            render: (_, __, rowIndex) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>{rowIndex + 1}</div>
            )
        },
        {
            title: 'Tên',
            key: 'name',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.name}
                </div>
            )
        },
        {
            title: 'Email',
            key: 'email',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.email}
                </div>
            )
        },
        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', width: 100, justifyContent: 'center' }}>
                    {record?.phone}
                </div>
            )
        },
        {
            title: 'Cơ sở',
            dataIndex: 'facility',
            key: 'facility',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.facility?.name}
                </div>
            )
        },
        {
            title: 'Vai trò',
            dataIndex: 'position',
            key: 'position',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.position?.name}
                </div>
            )
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: 100, }}>
                    <Tooltip title='Thông tin đánh giá'>
                        <span onClick={() => showDetailModal(record.id)}>
                            <GrView />
                        </span>
                    </Tooltip>
                </div>
            )
        },

    ]
    const columnsPinnoVate: ColumnType<any>[] = [
        {
            title: 'STT',
            render: (_, __, rowIndex) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>{rowIndex + 1}</div>
            )
        },
        {
            title: 'Tên',
            key: 'name',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.name}
                </div>
            )
        },
        {
            title: 'Email',
            key: 'email',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.email}
                </div>
            )
        },

        {
            title: 'Số điện thoại',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', width: 100, justifyContent: 'center' }}>
                    {record?.phone}
                </div>
            )
        },
        {
            title: 'Địa phương',
            key: 'address',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.address?.name}
                </div>
            )
        },
        {
            title: 'Vai trò',
            dataIndex: 'position',
            key: 'position',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.position?.name}
                </div>
            )
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center', cursor: 'pointer', width: 100, }}>
                    <Tooltip title='Thông tin đánh giá'>
                        <span onClick={() => showDetailModal(record.id)}>
                            <GrView />
                        </span>
                    </Tooltip>
                </div>
            )
        },
    ]
    const columnsDetail: ColumnType<any>[] = [
        {
            title: 'Tên phương diện',
            key: 'criteriaName',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.criteriaName}
                </div>
            )
        },
        {
            title: 'Điểm phương diện',
            key: 'criteriaPoint',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.total}
                </div>
            )
        },
        {
            title: 'Điểm tối đa phương diện',
            dataIndex: 'criteriaMaxPoint',
            key: 'criteriaMaxPoint',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.maxPoint}
                </div>
            )
        },
        {
            title: 'Điểm TB với hệ thống',
            dataIndex: 'averageOfCriteria',
            key: 'averageOfCriteria',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
                    {record?.averageOfCriteria.toFixed(2)}
                </div>
            )
        },
    ]
    const [data, setData] = useState<any[]>([])
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [pageSize, setPageSize] = useState(10); // Số bệnh lợn trên một trang
    const [loading, setLoading] = useState(false); // Trạng thái loading
    const [filter, setFilter] = useState(''); // Trạng thái loading
    const [isShowDetailModal, setIsShowDetailModal] = useState(false); // Trạng thái loading
    const [detailData, setDetailData] = useState<any>(); // Trạng thái loading
    const [selectType, setSelectType] = useState<any>('UINNOVATE'); // Trạng thái loading
    const [dataUinnovate, setDataUinnovate] = useState<any>()
    const [dataUinnovateShort, setDataUinnovateShort] = useState<any>()
    const [dataUimpact, setDataUimpact] = useState<any>()
    const [dataPinnoVate, setDataPinnoVate] = useState<any>()


    useEffect(() => {
        getAllUserAdmin()
    }, [selectType])
    // Hàm thực hiện lấy danh sách người dùng
    const getAllUserAdmin = async () => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }
        setLoading(true)
        await axios.get(`https://api.vinnovate.vn/administrators/userByAdmin?criteriaType=${selectType}&size=1000&offset=0`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then(res => {
                console.log(res?.data?.data?.items);
                setData(res?.data?.data?.items)
                setLoading(false)
                // setData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // Hàm thực hiện gán classname cho các dòng của bảng
    const getRowClassName = (record: any, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // Hàm thực hiện khi thay đổi trang hiện tại
    const handleChangePage = (page: number, pageSize?: number) => {
        // Mở loading
        setLoading(true);
        // Đẩy lên đầu trang
        // window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
            // Gán giá trị trang hiện tại và số bản ghi trên một trang
            setCurrentPage(page);
            pageSize && setPageSize(pageSize);
            // Tắt loading
            setLoading(false);
        }, 1000);
    };

    const sortData = filter.length === 0 ? data : data.filter((item: any) => item.email.toLowerCase().includes(filter));
    // Hàm thực hiện thay đổi các giá trị trong phân trang
    const paginationConfig = {
        current: currentPage,
        pageSize,
        total: sortData.length,
        onChange: handleChangePage,
        showSizeChanger: false,
        // showTotal: false,
        pageSizeOptions: ['10', '20', '30', '50'],
    };

    const onChangeSelect = async (value: any) => {
        setSelectType(value)
    }

    const showDetailModal = async (id: any) => {
        setLoading(true)
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        if (checkLogin) {
            checkLogin = checkLogin.slice(1);
            checkLogin = checkLogin.slice(0, checkLogin.length - 1);
        }

        await axios.get(`https://api.vinnovate.vn/administrators/results?userId=${id}`, {
            headers: {
                Authorization: `Bearer ${checkLogin}`
            }
        })
            .then(res => {
                console.log(res?.data?.data);
                if (res?.data?.data?.length === 0) {
                    setDataUinnovate(null)
                    setDataUimpact(null)
                    setDataPinnoVate(null)
                    notification.error({
                        message: 'Thông báo',
                        description: 'Người dùng này chưa có đánh giá nào',
                        placement: 'bottomRight',
                        duration: 3
                    } as any)
                    setLoading(false)
                    return;
                }
                res?.data?.data.map((item: any) => {
                    switch (item.type) {
                        case 'UINNOVATE':
                            setDataUinnovate(item)
                            setDataUinnovateShort(null)
                            setDataUimpact(null)
                            setDataPinnoVate(null)
                            break;
                        case 'UINNOVATE_SHORT':
                            setDataUinnovateShort(item)
                            setDataUinnovate(null)
                            setDataUimpact(null)
                            setDataPinnoVate(null)
                            break;
                        case 'UIMPACT':
                            setDataUimpact(item)
                            setDataUinnovate(null)
                            setDataUinnovateShort(null)
                            setDataPinnoVate(null)
                            break;
                        case 'PINNOVATE':
                            setDataPinnoVate(item)
                            setDataUinnovate(null)
                            setDataUinnovateShort(null)
                            setDataUimpact(null)
                            break;
                    }
                })
                setTimeout(() => {
                    setLoading(false)
                    setIsShowDetailModal(true)
                }, 1000);
                // setData(res.data)
            })
            .catch(err => {
                console.log(err)
            })


    }
    const exportToExcel = async () => {
        try {
            let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
            if (checkLogin) {
                checkLogin = checkLogin.slice(1);
                checkLogin = checkLogin.slice(0, checkLogin.length - 1);
            }
            const base64String = await axios.get(`https://api.vinnovate.vn/administrators/reportFile?type=${selectType}`, {
                headers: {
                    Authorization: `Bearer ${checkLogin}`
                }
            })
                .then(res => {
                    return res.data;
                })
                .catch(err => {
                    console.log(err);
                })
            // use fs to convert the buffer to a file
            console.log(base64String);
            const binaryData = atob(base64String);
            const arrayBuffer = new ArrayBuffer(binaryData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryData.length; i++) {
                view[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, `Thống kê người dùng ${selectType}.xlsx`);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='usermanagement-main'>
            <div className='usermanagement-title'>Thống kê người dùng</div>
            <div className='usermanagement-filter'>
                <Input
                    className='usermanagement-input'
                    placeholder="Tìm kiếm theo email người dùng"
                    width={200}
                    onChange={(event) => setFilter(event.target.value)}
                />
                <Select
                    placeholder="Chọn loại đánh giá"
                    defaultValue={'UINNOVATE'}
                    onChange={onChangeSelect}
                >
                    <Select.Option value="UINNOVATE">UINNOVATE</Select.Option>
                    <Select.Option value="UINNOVATE_SHORT">UINNOVATE RÚT GỌN</Select.Option>
                    <Select.Option value="UIMPACT">UIMPACT</Select.Option>
                    <Select.Option value="PINNOVATE">PINNOVATE</Select.Option>
                </Select>
            </div>
            <Spin spinning={loading} delay={500} tip="Đang lấy dữ liệu...">
                <div className="usermanagement-table">
                    {selectType === 'PINNOVATE' ?
                        <Table
                            className='table-content'
                            columns={columnsPinnoVate}
                            dataSource={sortData}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                            scroll={{ x: 1200 }}
                        />
                        :
                        <Table
                            className='table-content'
                            columns={columns}
                            dataSource={sortData}
                            rowClassName={getRowClassName}
                            pagination={paginationConfig}
                            scroll={{ x: 1200 }}
                        />
                    }

                </div>
                <div className='button-excel'>
                    <Button onClick={exportToExcel}>Xuất excel</Button>
                </div>
            </Spin>

            <Modal
                className='usermanagement-detail-modal'
                title=""
                visible={isShowDetailModal}
                onOk={() => setIsShowDetailModal(false)}
                onCancel={() => setIsShowDetailModal(false)}
                width={1000}
                bodyStyle={{ maxHeight: '600px', overflowY: 'auto' }} // Set the max height and enable vertical scrolling
                footer={
                    <div className='usermanagement-detail-footer'>
                        <Button
                            className='usermanagement-detail-footer-btn'
                            onClick={() => setIsShowDetailModal(false)}
                        >
                            Đóng
                        </Button>
                    </div>
                }
            >
                <div className='usermanagement-detail'>
                    {dataUinnovate &&
                        <>
                            <div className='usermanagement-detail-title'>Thông tin đánh giá Uinnovate</div>
                            <div className='usermanagement-detail-content'>
                                <div>Tổng điểm: {dataUinnovate.total}</div>
                                <div>Điểm tối đa: {dataUinnovate.maxPoint}</div>
                            </div>
                            <Table
                                className='table-content'
                                columns={columnsDetail}
                                dataSource={dataUinnovate.listCriteria}
                                rowClassName={getRowClassName}
                                scroll={{ x: 1200, y: 300 }}
                            />
                        </>
                    }
                    {
                        dataUimpact &&
                        <>
                            <div className='usermanagement-detail-title'>Thông tin đánh giá Uimpact</div>
                            <Table
                                className='table-content'
                                columns={columnsDetail}
                                dataSource={dataUimpact.listCriteria}
                                rowClassName={getRowClassName}
                                scroll={{ x: 1200, y: 300 }}
                            />
                        </>
                    }
                    {
                        dataPinnoVate &&
                        <>
                            <div className='usermanagement-detail-title'>Thông tin đánh giá PinnoVate</div>
                            <Table
                                className='table-content'
                                columns={columnsDetail}
                                dataSource={dataPinnoVate.listCriteria}
                                rowClassName={getRowClassName}
                                scroll={{ x: 1200, y: 300 }}
                            />
                        </>
                    }
                    {
                        dataUinnovateShort &&
                        <>
                            <div className='usermanagement-detail-title'>Thông tin đánh giá Uinnovate Short</div>
                            <Table
                                className='table-content'
                                columns={columnsDetail}
                                dataSource={dataUinnovateShort.listCriteria}
                                rowClassName={getRowClassName}
                                scroll={{ x: 1200, y: 300 }}
                            />
                        </>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default UserManagement
