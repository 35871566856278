import { ArrowRightOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Input, Modal, notification, Pagination, Radio, Steps } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import QuestionAPI from '../../api/questions/question.api';
import { ICriteria } from '../../common/u-innovate/define-criteria';
import { IAnswers, IGetAllQuestionsByCriteriaResponse, IQuestion, questions } from '../../common/u-innovate/define-question';
import { ISetOfQuestions } from '../../common/u-innovate/define-setOfQuestions';
import OtherTestIcon from '../../images/other-test-icon.png';

import Result from './Result';
import { motion } from 'framer-motion';
import { useDispatchRoot, useSelectorRoot } from '../../redux/store';
import { postAnswerRequest, postResultsRequest, putAnswerRequest, putResultsRequest, setAllQuestionsIsChosen, setAnswersIsChosen } from '../../redux/controller';
import { Answer, PostAnswerRequest, PostResultRequest, PutResultRequest } from '../../common/u-innovate/define-results';
import { IoMdCheckboxOutline } from "react-icons/io";

const fakeOtherTestLst = [
    {
        id: "1",
        title: "Lorem ipsum dolor sit amet consectetur. Ut morbi pellentesque vitae imperdiet. ",
        content: "Lorem ipsum dolor sit amet consectetur. Sit lorem non nullam id aliquam aliquam. Tellus integer porttitor mi blandit lobortis diam integer cursus. At id porttitor arcu eu cursus est. Arcu convallis quis lacus semper enim pretium cras. At nisi turp ..."
    },
    {
        id: "2",
        title: "Lorem ipsum dolor sit amet consectetur. Ut morbi pellentesque vitae imperdiet. ",
        content: "Lorem ipsum dolor sit amet consectetur. Sit lorem non nullam id aliquam aliquam. Tellus integer porttitor mi blandit lobortis diam integer cursus. At id porttitor arcu eu cursus est. Arcu convallis quis lacus semper enim pretium cras. At nisi turp ..."
    },
    {
        id: "3",
        title: "Lorem ipsum dolor sit amet consectetur. Ut morbi pellentesque vitae imperdiet. ",
        content: "Lorem ipsum dolor sit amet consectetur. Sit lorem non nullam id aliquam aliquam. Tellus integer porttitor mi blandit lobortis diam integer cursus. At id porttitor arcu eu cursus est. Arcu convallis quis lacus semper enim pretium cras. At nisi turp ..."
    },

]
const fakeSetOfQuestions1: IQuestion[] = [
    // {
    //     id: "1",
    //     content: "1.1 Nhà trường có tuyên bố sứ mệnh, tầm nhìn về đổi mới sáng tạo và khởi nghiệp",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "2",
    //     content: "1.2 Nhà trường có chiến lược về đổi mới sáng tạo và khởi nghiệp cho toàn trường",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "3",
    //     content: "1.3 Toàn bộ cán bộ, nhân viên, người học được truyền thông và hiểu rằng ĐMST&KN là ưu tiên hàng đầu của Nhà trường",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "4",
    //     content: "1.4 Lộ trình chiến lược về ĐMST&KN của Nhà trường được định dạng đơn giản, và được truyền thông rộng rãi trong toàn bộ Nhà trường",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "5",
    //     content: "1.5 Văn bản chiến lược về ĐMST&KN có đề cập đến việc thường xuyên xem xét, đánh giá, cập nhật cho phù hợp",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },

]

const fakeSetOfQuestions2: IQuestion[] = [
    // {
    //     id: "6",
    //     content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "7",
    //     content: "4.2 Nhà trường cho phép cộng đồng địa phương được tiếp cận, sử dụng cơ sở vật chất của Nhà trường",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "8",
    //     content: "4.3 Nhà trường hỗ trợ các công ty khởi nghiệp tại địa phương để tăng cường đổi mới và tăng trưởng",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },
    // {
    //     id: "9",
    //     content: "4.4 Nhà trường có sự hiện diện mạnh mẽ trong cộng đồng địa phương, bằng cách hỗ trợ các hoạt động văn hóa và nghệ thuật địa phương",
    //     answerLst: [
    //         {
    //             id: "1",
    //             content: "Hoàn toàn đồng ý"
    //         },
    //         {
    //             id: "2",
    //             content: "Đồng ý một phần"
    //         },
    //         {
    //             id: "3",
    //             content: "Không quan sát thấy"
    //         },
    //     ],
    //     pickedAnswer: null
    // },


]

const fakeSetOfQuestionsLst: ISetOfQuestions[] = [
    {
        id: '1',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: fakeSetOfQuestions1
    },
    {
        id: '2',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: fakeSetOfQuestions2
    },
    {
        id: '3',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '4',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '5',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '6',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '7',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '8',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },
    {
        id: '9',
        content: "4.1 Nhà trường tích cực tham gia vào việc phát triển và thực hiện các chiến lược ĐMST&KN của địa phương, khu vực và / hoặc quốc gia",
        questionLst: []
    },

]
interface MyProps {
    revertToIntro: () => void; // Chuyen qua lai giua cac phan cua danh gia
    revertToCriteria: () => void
    tranferFromTestToMoreTests: () => void;
    choseCriteria: any;
    numberOfQuestion: number;
}

const TakingTest = (props: MyProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSetOfQuestion, setCurrentSetOfQuestion] = useState<ISetOfQuestions>(); // Set lai moi khi chon phan pagination
    const [currentChoseAnswerId, setCurrentChoseAnswerId] = useState<number>(0);
    const [receivedResult, setReceivedResult] = useState<boolean>(false);
    let questionLstOfRequestBody: any[] = []; // doi lai form cua questionLst cho phu hop voi bodyRequest cua API
    let quantityOfEachTypeOfAnswer: number[] = [0, 0, 0]; // Lst luu lai so luong cau tra loi moi loai, vi tri 0 luu cau tra loi "quan sat hoan toan", 1 la "quan sat duoc 1 phan", 2 la "khong quan sat duoc"    const [numberOfQuestionsAnswered, setNumberOfQuestionsAnswered] = useState<number>(0); // Kiểm tra xem đã điền hết đáp án của trang để hiển thị nút tiếp tuc
    const [numberOfQuestions, setNumberOfQuestions] = useState<number>(0);
    const [quantityOfEachTypeOfAnswerUseState, setquantityOfEachTypeOfAnswerUseState] = useState<number[]>([0, 0, 0]);
    const [numberOfQuestionsAnswered, setNumberOfQuestionsAnswered] = useState<number>(0); // Kiểm tra xem đã điền hết đáp án của trang để hiển thị nút tiếp tuc
    const [totalScoreOfQuestionList, setTotalScoreOfQuestionList] = useState<number>(0); // Tổng số điểm của danh sách câu hỏi
    const [checkNextBtn, setCheckNextBtn] = useState<boolean>(false); // Kiểm tra xem đã điền hết đáp án của trang để hiển thị nút tiếp tuc
    const { lstQuestionsByCriteria, criteriaLst, tmplstQuestionsByCriteria } = useSelectorRoot((state) => state.uinnovate);
    const { tokenLogin, isExistEmail, registerSuccess, changePasswordSuccess, user } = useSelectorRoot((state) => state.login);

    const [beforeLstQuestionsByCriteria, setBeforeLstQuestionsByCriteria] = useState<IGetAllQuestionsByCriteriaResponse[]>([]);

    const [countAnswer, setCoundAnswer] = useState<number>(0);
    const [userType, setUserType] = useState<string>('');

    const [isOpenSaveModal, setIsOpenSaveModal] = useState<boolean>(false);

    const dispatch = useDispatchRoot()
    const [positionIdStaffInChangeStartUpInnovate, setPositionIdStaffInChangeStartUpInnovate] = useState<string>('63fed40404d0683c30798350')


    useEffect(() => {
        // setBeforeLstQuestionsByCriteria(tmplstQuestionsByCriteria);
        console.log("tmplstQuestionsByCriteria", tmplstQuestionsByCriteria);
        setNumberOfQuestionsAnswered(0);
    }, [])

    useEffect(() => {
        let type = localStorage.getItem('userType') ? localStorage.getItem('userType') : '';
        if (type) {
            type = type.slice(1);
            type = type.slice(0, type.length - 1);
            setUserType(type);
        }
    }, [])

    // useEffect(() => {
    // }, [tmplstQuestionsByCriteria])

    useEffect(() => {
        countQuestionIsAnswered();
        checkIsPartOfQuestionIsAnswered();
    }, [currentChoseAnswerId])

    useEffect(() => {
        checkIsPartOfQuestionIsAnswered();
    }, [currentIndex])


    // Đếm số lượng câu hỏi đã được trả lời 
    const countQuestionIsAnswered = () => {
        // let tmp:  = tmplstQuestionsByCriteria;

        let n = tmplstQuestionsByCriteria.length;
        let numberAnswered = 0;
        // tmplstQuestionsByCriteria[currentIndex].questions.map((item, indexitem) => ( // Sau nay se thay bang useState currentSetOfQuestion

        for (let i = 0; i < n; i++) {
            tmplstQuestionsByCriteria[i].questions.forEach(question => {
                question.answers.forEach(answer => {
                    if (answer.isChosen) numberAnswered += 1;
                })
            })
        }

        setNumberOfQuestionsAnswered(numberAnswered);
    }

    // Kiểm tra xem từng trang của danh sách câu hỏi đã được trả lời hết chưa
    const checkIsPartOfQuestionIsAnswered = () => {
        let n = tmplstQuestionsByCriteria.length;
        for (let i = 0; i < n; i++) {
            if (i === currentIndex) {
                tmplstQuestionsByCriteria[i].questions.forEach(question => {
                    question.answers.forEach(answer => {
                        if (!answer.isChosen) {
                            setCheckNextBtn(false);
                        }
                    })
                })
            }
        }

        tmplstQuestionsByCriteria.length === currentIndex + 1 ? setCheckNextBtn(false) : setCheckNextBtn(true);
    }

    const checkWhetherDoneTest = () => { // Check xem nguoi dung da nhap het cau tra loi chua
        if (numberOfQuestionsAnswered === props.numberOfQuestion)
            return true;
        return false;
    }

    // const handleChangePagination = () => {
    //     setCurrentIndex(currentIndex + 1);
    //     setCheckNextBtn(false);
    // };
    // const handleBackPagination = () => {
    //     setCurrentIndex(currentIndex - 1);
    //     setCheckNextBtn(true);
    // }

    const handlePageChange = (page: number) => {
        setCurrentIndex(page - 1);
    };

    const handleFinishTest = async () => { // Neu da nhap het cau tra loi thi se call API tinh toan diem 
        console.log(tmplstQuestionsByCriteria);
        showSaveModal();

    }
    const onHandleClickAnswer = (indexitem: number, index: number) => {
        const req = {
            currentIndex: currentIndex,
            indexitem: indexitem,
            index: index,
        }
        setCurrentChoseAnswerId(currentChoseAnswerId + 1);
        dispatch(setAnswersIsChosen(req));
    }

    const showSaveModal = () => {
        setIsOpenSaveModal(true);
    };

    const handleOkSaveModal = () => {
        let answerLst: Answer[] = [];
        console.log(tmplstQuestionsByCriteria);

        tmplstQuestionsByCriteria.map((item, index) => {
            item.questions.map((question, index) => {
                question.answers.map((answer, index) => {
                    if (answer.isChosen) {
                        const before = question.question.description === 'string' ? false : true;
                        answerLst.push({
                            questionId: answer.questionId,
                            answerId: answer.id,
                            point: answer.point,
                            isBefore: before,
                            additionalProp1: {}
                        })
                    }
                })
            })
        });

        console.log(answerLst);

        const req: PostResultRequest = {
            criteriaId: props.choseCriteria.criteriaId,
            listAnswer: answerLst,
            additionalProp1: {}
        }
        props.choseCriteria.isAnswered ? dispatch(putResultsRequest(req)) : dispatch(postResultsRequest(req));
        props.revertToCriteria();
        setIsOpenSaveModal(false);
        // window.location.reload();
    };

    const handleCancelSaveModal = () => {
        setIsOpenSaveModal(false);
    };

    const onClickBackBread = () => {
        showSaveModal();
        // props.revertToCriteria()
    }

    const handleChangeInputAnswer = (e: ChangeEvent<HTMLInputElement>, subitem: questions, indexitem: number) => {
        console.log(e.target.value);
        const req: PostAnswerRequest = {
            criteriaId: props.choseCriteria.criteriaId,
            questionId: subitem.question.id,
            content: e.target.value,
            description: "",
            point: 0,
            additionalProp1: {}
        }
        console.log(subitem);
        subitem.answers.length > 0 ? dispatch(putAnswerRequest(req)) : dispatch(postAnswerRequest(req));
    }
    return (
        <div className='taking-test'>
            {
                // receivedResult && quantityOfEachTypeOfAnswerUseState &&
                // <Result
                //     // receivedResult={receivedResult}
                //     // quantityOfEachTypeOfAnswer={quantityOfEachTypeOfAnswerUseState}
                //     // doneQuestionLst={props.questionLst}
                //     revertToCriteria={props.revertToCriteria}
                //     setReceivedResult={setReceivedResult}
                // // totalScoreOfQuestionList={totalScoreOfQuestionList}
                // // numberOfQuestionList={props.numberOfQuestions}
                // />
            }
            {
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a onClick={() => onClickBackBread()}>Đánh giá</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a onClick={() => onClickBackBread()}>Bắt đầu đánh giá</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className='present-link'>
                            {props.choseCriteria.name}
                        </Breadcrumb.Item>

                    </Breadcrumb>
                    <div className='test-body'>
                        <div className='test-detail'>
                            <div className='title'>{props.choseCriteria.name}</div>
                            <div className='text'>{props.choseCriteria.description}</div>
                            <div className='test-content'>
                                {tmplstQuestionsByCriteria[currentIndex] &&
                                    <div className={'taking-test-area ' + userType}>
                                        {/* Khi call API se thay doan duoi nay thanh currentSetOfQuestion.content */}
                                        <div className='sub-title'>
                                            {(userType !== 'PINNOVATE' && userType !== 'UINNOVATE_SHORT') &&
                                                tmplstQuestionsByCriteria[currentIndex]?.setOfQuestions.name
                                            }
                                            {userType === 'PINNOVATE' &&
                                                <div>
                                                    <b>{tmplstQuestionsByCriteria[currentIndex]?.setOfQuestions.name}</b> <br />
                                                    <p>{tmplstQuestionsByCriteria[currentIndex]?.setOfQuestions.description}</p>
                                                </div>
                                            }
                                        </div>

                                        <div className={'question-lst ' + (currentIndex + 1)}  >
                                            {
                                                tmplstQuestionsByCriteria[currentIndex].questions.map((item, indexitem) => ( // Sau nay se thay bang useState currentSetOfQuestion
                                                    <>
                                                        {userType === 'UIMPACT' ?
                                                            <div key={indexitem}>
                                                                <div className='content'>{item.question.content}</div>
                                                                {(item.answers.length > 0 && item.answers[0].point > 0) ?
                                                                    <div className='options-of-answer'>
                                                                        {
                                                                            item.answers.map((subitem, indexsubitem) => (
                                                                                <label className='lst-item'
                                                                                    key={indexsubitem}
                                                                                    onClick={() => {
                                                                                        onHandleClickAnswer(indexitem, indexsubitem)
                                                                                    }}
                                                                                >
                                                                                    <input
                                                                                        type="radio" className="radio-btn"
                                                                                        checked={subitem.isChosen === true}
                                                                                        value={subitem.id} id={subitem.id} name={subitem.id.toString()}
                                                                                    />
                                                                                    <div className="label">{subitem.content.charAt(0).toUpperCase() + subitem.content.slice(1).toLowerCase()}</div>
                                                                                </label>
                                                                            ))}
                                                                    </div>
                                                                    :
                                                                    <div className='options-of-answer input-answer'>
                                                                        {item.answers.length > 0 ?
                                                                            <Input
                                                                                className='input-login email-input'
                                                                                placeholder='Nhập số liệu'
                                                                                type='number'
                                                                                id={item.question.id}
                                                                                onChange={(e) => {
                                                                                    handleChangeInputAnswer(e, item, indexitem)
                                                                                }}
                                                                                defaultValue={item.answers[0].content}
                                                                                name={item.answers[0].id.toString()}
                                                                            />
                                                                            :
                                                                            <Input
                                                                                className='input-login email-input'
                                                                                placeholder='Nhập số liệu'
                                                                                type='number'
                                                                                onChange={(e) => {
                                                                                    handleChangeInputAnswer(e, item, indexitem)
                                                                                }}
                                                                            />
                                                                        }
                                                                    </div>
                                                                }

                                                            </div>
                                                            :
                                                            <div key={indexitem}>

                                                                {
                                                                    userType === 'UINNOVATE_SHORT' &&
                                                                    <div className='sub-title' style={{ fontSize: 16 }}> {item.question.title}</div>
                                                                }

                                                                <div className='content' title={item.question.id}>{item.question.content}</div>
                                                                <div className='options-of-answer'>
                                                                    {
                                                                        item.answers.map((subitem, indexsubitem) => (
                                                                            <label className='lst-item'
                                                                                key={indexsubitem}
                                                                                onClick={() => {
                                                                                    onHandleClickAnswer(indexitem, indexsubitem)
                                                                                }}
                                                                            >
                                                                                <input
                                                                                    type="radio" className="radio-btn"
                                                                                    checked={subitem.isChosen === true}
                                                                                    value={subitem.id} id={subitem.id} name={subitem.id.toString()}
                                                                                />
                                                                                <div className="label">{subitem.content.charAt(0).toUpperCase() + subitem.content.slice(1).toLowerCase()}</div>
                                                                            </label>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                ))
                                            }
                                        </div>
                                        <div className='footer'
                                            style={{ justifyContent: user?.type !== 'UINNOVATE_SHORT' ? 'space-between' : 'flex-end' }}
                                        >
                                            {
                                                (user?.type !== 'UINNOVATE_SHORT') &&
                                                <Pagination className='pagination' current={currentIndex + 1} total={tmplstQuestionsByCriteria.length * 10} onChange={handlePageChange} showLessItems={true} />
                                            }
                                            <div className='button-group'>
                                                <div className='number-of-questions-answered'>
                                                    Đã trả lời: {numberOfQuestionsAnswered}/{props.numberOfQuestion}
                                                </div>
                                                {/* {currentIndex > 0 &&
                                                    <motion.div className='taking-test-button'
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}>
                                                        <Button className='button' onClick={() => { handleBackPagination() }}>Quay lại</Button>
                                                    </motion.div>
                                                }
                                                {checkNextBtn &&
                                                    <motion.div className='taking-test-button'
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}>
                                                        <Button className='button' onClick={() => { handleChangePagination() }}>Tiếp tục</Button>
                                                    </motion.div>
                                                } */}
                                                {
                                                    (checkWhetherDoneTest()) &&
                                                    <motion.div className='taking-test-button'
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.95 }}>
                                                        <Button
                                                            className='button'
                                                            onClick={() => { handleFinishTest() }}
                                                        >Hoàn thành
                                                        </Button>
                                                    </motion.div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="note-taking-test">
                                    <div className="note-title">HƯỚNG DẪN THỰC HIỆN BÀI ĐÁNH GIÁ</div>
                                    {/* <div className="note-content">Lorem ipsum dolor sit amet consectetur. Quisque quis ut sed sed ultrices facilisi. Mi in malesuada erat ac bibendum eget tristique. Tristique quam nunc dolor tempus varius fusce. Lacus tincidunt tellus nec sit. Nibh tincidunt integer varius tempus elit velit imperdiet a. Pellentesque sociis egestas sed nunc ultrices elementum id dui. Aliquam in sed tristique suspendisse sit. Nulla consectetur pharetra viverra magna. Lacus malesuada hendrerit feugiat sit proin massa at. Volutpat ultricies placerat sapien gravida sed risus vitae.</div> */}
                                    {/* <div className="note-content">Placerat eget nisl dictum augue vitae et massa. Viverra a maecenas id amet eget cras egestas velit. Etiam scelerisque eleifend cras in sed est diam ultrices. Quam aliquam dictum purus tincidunt id viverra netus faucibus. Vestibulum aliquam enim ac mauris nulla diam mi faucibus. Elit ornare at erat integer mus euismod blandit tellus. Semper dui varius aliquet tristique lorem accumsan eget. Turpis cras tincidunt pharetra sit dui massa eleifend malesuada. Odio enim odio morbi in.</div> */}
                                    {userType === 'UIMPACT' &&
                                        <div className="note-content">
                                            Lưu ý:
                                            <ul>
                                                <li>Lựa chọn đáp án theo phản xạ nhanh và tự nhiên nhất đối với bản thân mình.</li>
                                                <li>Không cố gắng lựa chọn đáp án hoàn hảo, mà hãy trả lời trung thực nhất dựa trên quan sát và đánh giá cá nhân của mình về thực hành của cơ sở đào tạo</li>
                                            </ul>
                                        </div>
                                    }

                                    {(userType === 'UINNOVATE' || userType === 'UINNOVATE_SHORT') &&
                                        <>
                                        <div className="note-content">
                                            Quy tắc tính điểm:
                                        </div>
                                        <div className='note-content-items'>
                                            <div className='note-content-item'>
                                                <span>
                                                    <IoMdCheckboxOutline />
                                                </span>
                                                Không đồng ý = Nếu không đồng ý nhà trường có thực hành này
                                            </div>

                                            <div className='note-content-item'>
                                                <span>
                                                    <IoMdCheckboxOutline />
                                                </span>
                                                Đồng ý một phần = Nếu quan sát thấy nhà trường bắt đầu có những thực hành cơ bản, nhưng chưa đầy đủ, toàn diện
                                            </div>

                                            <div className='note-content-item'>
                                                <span>
                                                    <IoMdCheckboxOutline />
                                                </span>
                                                Hoàn toàn đồng ý = Nếu quan sát thấy nhà trường thực hành đẩy đủ, toàn diện nội dung liên quan
                                            </div>

                                        </div>
                                    </>
                                    }
                                    {userType === 'PINNOVATE' &&
                                        <div className="note-content">
                                            Lưu ý:
                                            <ul>
                                                <li>Lựa chọn đáp án theo phản xạ nhanh và tự nhiên nhất đối với bản thân mình.</li>
                                                <li>Không cố gắng lựa chọn đáp án hoàn hảo, mà hãy trả lời trung thực nhất dựa trên quan sát và đánh giá cá nhân của mình về thực hành của cơ sở đào tạo</li>
                                                <li>Bài đánh giá được thực hiện trên thang điểm từ 1 đến 10, trong đó 10 là cao nhất và 1 là thấp nhất</li>
                                            </ul>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal
                className='taking-test-save-modal'
                title="Lưu thay đổi"
                visible={isOpenSaveModal}
                onOk={handleOkSaveModal}
                onCancel={handleCancelSaveModal}
                footer={[
                    <motion.div
                        whileHover={{ scale: 1.1, opacity: 0.75 }}
                        whileTap={{ scale: 0.95 }}>
                        <Button className='btn-back-test' key="back" onClick={handleCancelSaveModal}>
                            Quay lại
                        </Button>
                    </motion.div>,
                    <motion.div
                        whileHover={{ scale: 1.1, opacity: 0.75 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Button className='btn-save-test' key="submit" type="primary" onClick={handleOkSaveModal}>
                            Xác nhận
                        </Button>
                    </motion.div>
                ]}
            >
                <div className="title">
                    Bạn có muốn lưu thay đổi ?
                </div>
                <div className="body">
                    Khi bấm vào xác nhận, các nội dung bạn đã hoàn thành sẽ được lưu lại, đồng thời hệ thống sẽ chuyển bạn sang trả lời phương diện đánh giá tiếp theo
                </div>
            </Modal>
        </div>
    )
}

export default TakingTest