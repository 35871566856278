import { Button, notification } from 'antd';
import IntroduceMethodImg from '../../images/introduce_method.png';
import './styles.judgement.scss';
import { useSelectorRoot } from '../../redux/store';
import { Variants, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

interface MyProps {
    tranferFromIntroToCriteria: () => void
}
const imageVariants: Variants = {
    offscreen: {
        y: 100,
        opacity: 0
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 2
        }
    }
};
const IntroduceMethod = (props: MyProps) => {
    const { tokenLogin, user } = useSelectorRoot((state) => state.login);
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [userType, setUserType] = useState<string>('')
    const [userPosition, setUserPosition] = useState<string>('')
    const [positionIdStaffInChangeStartUpInnovate, setPositionIdStaffInChangeStartUpInnovate] = useState<string>('63fed40404d0683c30798350')
    useEffect(() => {
        console.log('IntroduceMethod user', user);
        if (user) {
            setUserPosition(user.position.id);
        }
    }, [user]);


    useEffect(() => {
        let checkLogin = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        let type = localStorage.getItem('userType') ? localStorage.getItem('userType') : '';
        if (checkLogin) setIsLogin(true);
        if (type) {
            type = type.slice(1);
            type = type.slice(0, type.length - 1);
            setUserType(type);
        }
    });

    const handleOnClick = () => {
        if (isLogin)
            props.tranferFromIntroToCriteria();
        else
            notification['warning']({
                message: 'Vui lòng đăng nhập tài khoản trước',
                style: {
                    width: '100%'
                }
            });
    }
    return (

        <div className='intro-part'>
            <div className='text-of-intro'>
                {userType === "" &&
                    <>
                        <div className='title'>Giới thiệu phương pháp được sử dụng trong bảng khảo sát</div>
                        <div className='detail'>V.innovate là một nền tảng gồm 3 công cụ (U.innovate; U.impact; P.innovate) tự đánh giá miễn phí dành cho tất cả các loại hình tổ chức giáo dục đại học; các doanh nghiệp khởi nghiệp; các tỉnh/thành phố. Các công cụ cho phép bạn đánh giá tổ chức của mình bằng cách sử dụng một số tuyên bố liên quan đến các hoạt động kinh doanh của tổ chức, bao gồm lãnh đạo, nhân sự và liên kết với doanh nghiệp. Các tài liệu hỗ trợ và đào tạo phong phú, bao gồm các nghiên cứu điển hình thực tế, luôn sẵn sàng để hỗ trợ các hội thảo và phát triển hơn nữa trong tổ chức của bạn.</div>
                    </>
                }
                {(userType === "UINNOVATE" || userType === 'UINNOVATE_SHORT') &&
                    <>
                        <div className='title'>Giới thiệu bộ tiêu chí đánh giá trường đại học khởi nghiệp</div>
                        <div className='detail'>U.innovate là công cụ tự đánh giá, được truyền cảm hứng từ mô hình đại học khởi nghiệp của OECD. Các trường đại học thông qua U.innovate có thể quan sát được các điểm mạnh, điểm yếu trong hệ sinh thái đổi mới sáng tạo và khởi nghiệp của trường mình. Đồng thời có thể so sánh và đối chiếu về sự phát triển của nhà trường cùng với thời gian, cũng như so sánh với trung bình những trường khác tại Việt Nam.</div>
                        <div className='detail'>Để bắt đầu tự đánh giá, hãy chọn một trong 8 tiêu chí.  Trong mỗi khía cạnh, các câu lệnh đã được thiết kế để bạn có thể đánh giá chúng theo 3 mức độ (không đồng ý, đồng ý một phần, hoàn toàn đồng ý). Vui lòng sử dụng thanh trượt bên dưới các câu để ghi điểm.</div>
                        <div className='detail'>U.innovate - Bộ tiêu chí đánh giá trường đại học khởi nghiệp được phát triển thành 2 bộ: Bộ tiêu chí chuyên sâu (long-form) và Bộ tiêu chí rút gọn (short-form).</div>
                        <div className='detail'>
                            Làm thế nào để lựa chọn bảng hỏi phù hợp
                        </div>
                        <div className='detail info'>
                            <strong>1.</strong> Bảng hỏi chuyên sâu (long-form): dành cho “Cán bộ phụ trách đổi mới sáng tạo và khởi nghiệp” ở mục đăng ký “Vai trò tại cơ sở đào tạo”
                        </div >
                        <div className='detail info'>
                            <strong>2.</strong> Bảng hỏi rút gọn (short-form): dành cho “Sinh viên/  Cựu sinh viên/ Cán bộ phụ trách mảng tác động xã hội/ Cán bộ giảng viên tại cơ sở đào tạo” ở mục đăng ký “Vai trò tại cơ sở đào tạo”
                        </div>

                        {userPosition === positionIdStaffInChangeStartUpInnovate &&
                            <div className='detail position' >
                                Vì vai trò của bạn là "Cán bộ phụ trách đổi mới sáng tạo và khởi nghiệp của Cơ sở đào tạo" nên sẽ được sử dụng bảng hỏi đầy đủ.
                            </div>
                        }

                        {userPosition !== positionIdStaffInChangeStartUpInnovate &&
                            <div className='detail position'>
                                Vì vai trò của bạn không phải là "Cán bộ phụ trách đổi mới sáng tạo và khởi nghiệp của Cơ sở đào tạo" nên sẽ được sử dụng bảng hỏi rút gọn.
                            </div>
                        }
                    </>
                }
                {userType === "UIMPACT" &&
                    <>
                        <div className='title' >Giới thiệu phương pháp được sử dụng trong bảng khảo sát</div>
                        <div className='detail'>U.impact là công cụ tự đánh giá mức độ tác động xã hội của trường đại học thông qua 17 mục tiêu phát triển bền vững thuộc Liên Hợp Quốc. Các trường đại học thông qua U.impact có thể quan sát được các tác động của mình cho cộng đồng, xã hội.</div>
                        <div className='detail'>
                            Phương pháp đánh giá
                            <ul>
                                <li>Bộ tiêu chí được truyền cảm hứng từ phương pháp của Tạp chí Times</li>
                                <li>Mỗi nhà trường xác định 03 mục tiêu phát triền bền vững (SDG) dược coi là cốt lõi mà nhà trường theo đuổi</li>
                                <li>Sau khi lựa chọn, một bảng hỏi với các tiêu chí giúp đánh giá mức độ tạo tác động xã hội của Nhà trường với 04 SDG bao gồm: SDG 17 (quan hệ đối tác bền vững, là bắt buộc với tất cả các trường đại học) và 03 SDG đã lựa chọn ở trên</li>
                                <li>Bảng hỏi không đòi hỏi Nhà trường phải cung cấp dữ liệu mà tập trung vào tự đánh giá xem mức độ hiểu biết và thực hiện ở chiều rộng và chiều sâu với 5 cấp độ</li>
                                <li>Kết quả đánh giá Nhà trường sẽ quan sát được từng mảng thực hiện của mình dang ở mức độ nào, so sánh các trường khác trên thị trường, so sánh với chính mình theo thời gian</li>
                            </ul>
                        </div>
                    </>
                }
                {userType === "PINNOVATE" &&
                    <>
                        <div className='title'>Giới thiệu phương pháp được sử dụng trong bảng khảo sát</div>
                        <div className='detail'>P.innovate là công cụ tự đánh giá cho các cộng đồng khởi nghiệp tại từng tỉnh/thành phố có thể quan sát được các chính sách, nguồn lực, văn hoá tác động tới hệ sinh thái khởi nghiệp của mình. Đồng thời có thể so sánh và đối chiếu về sự phát triển của hệ sinh thái tại địa phương mình cùng với thời gian, cũng như so sánh với trung bình những tỉnh thành khác tại Việt Nam.</div>
                        <div className='detail'>
                            Phương pháp đánh giá
                            <ul>
                                <li>Để có một hệ sinh thái khởi nghiệp mạnh mẽ và là cái nôi ươm mầm các doanh nghiệp khởi nghiệp sáng tạo, cần có 3 nhóm cấu thành cơ bản sau: thứ nhất, cần có các nguyên tắc về phát triển hoạt động khởi nghiệp; thứ hai, cần có các chủ thể vận hành trong hệ sinh thái và thứ ba, các cầu phần cơ sở tạo thành hệ sinh thái</li>
                                <li>Bộ tiêu chí đánh giá của p.innovate gồm có 10 nguyên tắc vận hành, 12 chủ thể và 12 cấu phần của hệ sinh thái. </li>
                                <li>Các địa phương sẽ tự đánh giá, được đánh giá về mức độ phát triển trên thang điểm từ 1 đến 10 của hệ sinh thái khởi nghiệp của mình ở thời điểm hiện nay và 5 năm trước đây, để quan sát mức độ phát triển của mình.  </li>
                            </ul>
                        </div>
                    </>
                }
                <motion.div
                    className='button-start btn-intro-part'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ marginTop: 10 }}
                >
                    <Button id='btn-intro-part' onClick={handleOnClick} >BẮT ĐẦU TỰ ĐÁNH GIÁ</Button>
                </motion.div>
            </div>
            {(userType === "UIMPACT" || userType === "PINNOVATE") &&
                <div className='image-of-intro uimpact'><motion.img src={IntroduceMethodImg} alt=''
                    variants={imageVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 'all' }} /></div>}
            {(userType !== "UIMPACT" && userType !== "PINNOVATE") && <div className='image-of-intro'><motion.img src={IntroduceMethodImg} alt=''
                variants={imageVariants}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 'all' }} /></div>}

        </div>
    )
}

export default IntroduceMethod