/* eslint-disable new-parens */
import SYSTEM_CONSTANTS from "../../common/constants";
import HttpClient from "../http-client";
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from "rxjs/operators";
import { IPosition } from "../../common/u-innovate/define-position";
import { IStatistics } from "../../common/u-innovate/define-statistics";

export default class StatisticsAPI {
    static host = 'https://api.vinnovate.vn';

    static getHomeStatistics(): Observable<IStatistics | null> {
        const api = `${StatisticsAPI.host}/${SYSTEM_CONSTANTS.API.STATISTICS.HOME_STATISTICS}`;
        return HttpClient.get(api, {}).pipe(
            map((res) => res as IStatistics || null, catchError((error) => new Observable))
        );
    }


}