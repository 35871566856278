import { motion } from 'framer-motion'
import React from 'react'
import './privacy_prolicy.scss'
const LegalNotice = () => {
    return (
        <motion.div className='privacy-policy-main'
            initial={{ width: 0 }}
            animate={{ width: 900 }}
            exit={{ x: window.innerWidth, transition: { duration: 0.5 } }}
        >
            <div className="header">
                <div className="title">Điều khoản</div>
                <div className="content">BẰNG CÁCH SỬ DỤNG TRANG WEB VÀ DỊCH VỤ NÀY, BẠN ĐỒNG Ý CHỊU RÀNG BUỘC VỚI CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN NÀY.</div>
            </div>
            <div className="body">
                <div className="title">
                    I. Giới Thiệu Trang Web
                </div>
                <div className="content">
                    <div className='sub-content'>1. Mục tiêu chung của Trang web là cung cấp quyền truy cập vào công cụ tự đánh giá của Vinnovate (sau đây gọi là "Dịch vụ"). Vinnovate là một công cụ được thiết kế để giúp các tổ chức quan tâm tự đánh giá bản thân dựa trên những tuyên bố đặc trưng của một tổ chức giáo dục đại học/ tỉnh, thành phố khởi nghiệp. Là một công cụ tự đánh giá, Vinnovate có mục đích giúp các cơ sở giáo dục đại học xác định tình hình hiện tại và các lĩnh vực tiềm năng để hành động trong tương lai.</div>
                    <div className='sub-content'>2. Quyền truy cập vào Dịch vụ và các tài liệu được cung cấp qua Trang web được mở. Tuy nhiên, nó yêu cầu xác thực.</div>
                    <div className='sub-content'>3. Chúng tôi cam kết đảm bảo tính khả dụng của Trang web không bị gián đoạn. Tuy nhiên, quyền truy cập vào Trang web và Dịch vụ của nó đôi khi có thể bị đình chỉ, hạn chế hoặc cản trở để cho phép sửa chữa, bảo trì hoặc giới thiệu các dịch vụ hoặc phương tiện mới.</div>
                </div>
                <div className="title">
                    II. Định nghĩa
                </div>
                <div className="content">
                    <div className="sub-content">Toàn bộ công cụ tự đánh giá của Vinnovate cung cấp cho người dùng khả năng thực hiện đánh giá hồ sơ doanh nhân của họ, đồng thời cung cấp quyền truy cập vào các báo cáo phù hợp dựa trên thông tin đầu vào của người dùng.</div>
                    <div className="sub-content">Thông tin và Tài liệu có nghĩa là bất kỳ nội dung nào bao gồm dữ liệu, văn bản, hình ảnh hoặc video có sẵn trên Trang web Vinnovate và trong các tài liệu được cung cấp sau khi thực hiện tự đánh giá.</div>
                    <div className="sub-content">Điều khoản chung có nghĩa là các điều khoản và điều kiện hiện tại mà bất kỳ việc sử dụng Trang web, Dịch vụ hoặc Thông tin và Tài liệu nào của Trang web đều phải tuân theo.</div>
                </div>
                <div className="title">
                    III. Điều khoản áp dụng
                </div>
                <div className="content">
                    <div className="sub-content">1. Việc truy cập và sử dụng bất kỳ Thông tin, Tài liệu hoặc Dịch vụ nào của Trang web được điều chỉnh bởi các Điều khoản và Điều kiện sử dụng chung này và ngụ ý người dùng chấp nhận các Điều khoản chung này.</div>
                    <div className="sub-content">2. Chúng tôi có quyền sửa đổi các Điều khoản chung này và bất kỳ điều khoản cụ thể nào khác trên Trang web vào bất kỳ lúc nào bằng cách đăng các điều khoản và điều kiện đã sửa đổi trên Trang web. Những sửa đổi như vậy sẽ có hiệu lực vào ngày chúng được đăng.</div>
                </div>
                <div className="title">
                    IV. Quyền sở hữu
                </div>
                <div className="content">
                    <div className="sub-content">Thông tin được công bố trên Trang web và Dịch vụ thuộc sở hữu của Viện IID.</div>
                    <div className="sub-content">Quyền sở hữu, bao gồm bản quyền, áp dụng cho tất cả nội dung có sẵn thông qua Trang web này được trao cho chủ sở hữu tương ứng của họ. Việc truy cập thông tin, tài liệu và Dịch vụ của Trang web không cung cấp cho người dùng bất kỳ quyền sở hữu nào đối với chúng. Tất cả các quyền không được cấp cụ thể theo đây được bảo lưu bởi (những) chủ sở hữu tương ứng.</div>
                </div>
                <div className="title">
                    V. Quyền sử dụng và giới hạn
                </div>
                <div className="content">
                    <div className="sub-content">1. Quyền truy cập vào Thông tin, Tài liệu và Dịch vụ của Trang web này được cấp cho các mục đích được mô tả ở điểm I ở trên.
                    </div>
                    <div className="sub-content">2. Việc sử dụng Thông tin, Tài liệu và Dịch vụ của Trang web này được quy định như sau.
                    </div>
                    <div className="sub-content">Người dùng có thể:
                        <ul>
                            <li>Sử dụng cho mục đích phi thương mại của riêng họ mà không sửa đổi Thông tin, Tài liệu và Dịch vụ của Trang web này.
                            </li>
                            <li>Tiến hành tự đánh giá, như mô tả ở trên.
                            </li>
                        </ul>
                    </div>
                    <div className="sub-content">Người dùng không được:
                        <ul>
                            <li>Sử dụng Thông tin, Tài liệu hoặc Dịch vụ cho mục đích thương mại.
                            </li>
                            <li>Thực hiện bất kỳ hoạt động nào có thể gây hại hoặc vi phạm các mục đích của Trang web và Dịch vụ; đặc biệt, người dùng sẽ không ảnh hưởng đến trạng thái miễn phí của Trang web và Dịch vụ, bằng cách sử dụng nó cho mục đích thương mại.
                            </li>
                            <li>Thực hiện bất kỳ hoạt động nào có thể dẫn đến việc trích xuất Thông tin từ Trang web hoặc từ Dịch vụ.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="title">
                    VI.  Hạn chế
                </div>
                <div className="content">
                    <div className="sub-content">Giấy phép nói trên không bao gồm thông tin mà bất kỳ quyền sở hữu trí tuệ đã được cấp hoặc đang chờ xử lý nào được áp dụng (ví dụ: nhãn hiệu, thiết kế và quyền cơ sở dữ liệu). Trong những trường hợp đó và trong mọi trường hợp cần sử dụng cho mục đích thương mại, người dùng được khuyến khích xin phép cụ thể và rõ ràng từ Viện IID.</div>
                    <div className="sub-content">Các Điều khoản và Điều kiện Chung hiện tại không cho phép sử dụng logo và nhận dạng trực quan của Viện IID. Sau này phải tuân theo các điều khoản và điều kiện cụ thể. Bất kỳ quyền nào không được cấp một cách rõ ràng theo Điều khoản và Điều kiện chung hiện tại phải tuân theo các yêu cầu cụ thể để được Viện IID cho phép.
                    </div>
                </div>
                <div className="title">
                    VII.  Từ chối trách nhiệm
                </div>
                <div className="content">
                    <div className="sub-content">Mặc dù trang web này cố gắng giữ cho thông tin, tài liệu và dịch vụ kịp thời và chính xác, nhưng Viện IID không đưa ra tuyên bố, đảm bảo hoặc bảo đảm nào về tính chính xác, đầy đủ hoặc đầy đủ của các tài liệu và dịch vụ có sẵn trên trang web và do đó, Viện IID không chịu trách nhiệm hoặc trách nhiệm pháp lý nào liên quan đến thông tin trên trang web này.</div>
                    <div className="sub-content">Thông tin này:
                        <ul>
                            <li>Chỉ có tính chất chung và không nhằm giải quyết các trường hợp cụ thể của bất kỳ cá nhân hoặc tổ chức cụ thể nào.
                            </li>
                            <li>Không nhất thiết phải toàn diện, đầy đủ, chính xác hoặc cập nhật.
                            </li>
                            <li>Không phải là lời khuyên chuyên nghiệp hoặc pháp lý (nếu bạn cần lời khuyên cụ thể, bạn phải luôn hỏi ý kiến ​​của một chuyên gia có trình độ phù hợp).
                            </li>
                        </ul>
                        <div className="sub-content">Trang web này có thể chứa “các liên kết bên ngoài” đến các trang web thuộc các miền khác, có thể không thuộc sở hữu hoặc tài trợ của IID. Đối với các dịch vụ của IID không có quyền kiểm soát và IID không chịu trách nhiệm.
                        </div>
                    </div>
                </div>
                <div className="title">
                    VIII. Xử lý và bảo vệ dữ liệu
                </div>
                <div className="content">
                    <div className="sub-content">Trong những trường hợp trang web này yêu cầu thông tin cá nhân về bạn, thông tin đó sẽ được xử lý theo luật Bảo vệ Dữ liệu hiện hành.
                    </div>
                    <div className="sub-content">Trang web này chứa chính sách bảo mật cụ thể phù hợp với luật Bảo vệ Dữ liệu Việt Nam.
                    </div>
                </div>
                <div className="title">
                    IX. Tiếp xúc
                </div>
                <div className="content">
                    <div className="sub-content">Nếu có thắc mắc về các vấn đề liên quan đến Trang web hoặc Dịch vụ và để xin phép cụ thể và rõ ràng, vui lòng liên hệ:
                    </div>
                    <div className="sub-content">
                        Email:
                        <a className='item-text' href="mailto:hello@iid.org.vn">hello@iid.org.vn</a>
                    </div>
                    <div className="sub-content">
                        Điện thoại:
                        <a className='item-text' href="tel:+8424888651212">+84 329109899</a>
                    </div>
                    <div className="sub-content">
                        Social media:
                        <a className='item-text' href='https://www.facebook.com/iidvietnam' target="_blank">https://www.facebook.com/iidvietnam</a>
                    </div>

                </div>
            </div>
        </motion.div >
    )
}

export default LegalNotice
