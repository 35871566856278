/* eslint-disable new-parens */
import HttpClient from "../http-client";
import SYSTEM_CONSTANTS from "../../common/constants";
import axios from "axios";

import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from "rxjs/operators";
import { ICriteria } from "../../common/u-innovate/define-criteria";
import { GetAllQuestionByCriteriaIdRequest, IGetAllQuestionsByCriteriaResponse } from "../../common/u-innovate/define-question";
import { AllTotalResult, PostResultRequest, PutResultRequest, Result, SystemStatistics, TotalResultByCriteria } from "../../common/u-innovate/define-results";

export default class ResultAPI {
    static host = 'https://api.vinnovate.vn';

    static PostResult(req: PostResultRequest): Observable<Result | null> {
        const api = `${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.POST_RESULT}`;
        return HttpClient.post(api, req).pipe(
            map((res) => res as Result || null, catchError((error) => new Observable))
        );
    }

    static PutResult(criteriaId: string, req: PutResultRequest): Observable<Result | null> {
        const api = `${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.PUT_RESULT}/${criteriaId}`;
        return HttpClient.put(api, req).pipe(
            map((res) => res as Result || null, catchError((error) => new Observable))
        );
    }

    static GetStaticsOfResultByUser(): Observable<AllTotalResult | null> {
        const api = `${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GETSTATICSOFRESULTBYUSER}`;
        return HttpClient.get(api).pipe(
            map((res) => res as AllTotalResult || null, catchError((error) => new Observable))
        );
    }
    static GetStaticsOfResultByCriteria(criteriaId: string): Observable<TotalResultByCriteria[] | null> {
        const api = `${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GETSTATICSOFRESULTBYUSER}/${criteriaId}`;
        return HttpClient.get(api).pipe(
            map((res) => res as TotalResultByCriteria[] || null, catchError((error) => new Observable))
        );
    }

    static getSystemStatistics(): Observable<SystemStatistics | null> {
        const api = `${ResultAPI.host}/${SYSTEM_CONSTANTS.API.RESULT.GET_SYSTEM_STATISTICS}`;
        return HttpClient.get(api).pipe(
            map((res) => res as any || null, catchError((error) => new Observable))
        );
    }
}