import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  RadialLinearScale,
  Tooltip,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
// import ChartDataLabels from 'chartjs-plugin-datalabels';

// ChartJS.register(ChartDataLabels);
ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
interface Props {
  data: any;
  options: any;
}

const PolarAreaChart = (props: Props) => {
  return (
    <PolarArea data={props.data} options={props.options} />
  )
}

export default PolarAreaChart